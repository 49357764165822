import {Box, Flex} from '@semcore/ui/flex-box';

import {KeywordsDataSection} from './KeywordsDataSection';

import {KeywordDetailedInfoSection} from './KeywordDetailedInfoSection';
import {useEffect, useState} from 'react';
import {throttle} from '../../../utils/helpers';

export const TopKeywordsTab = () => {
  const [tableRowsAmount, setTableRowsAmount] = useState<number>(0);
  const [detailedRowsAmount, setDetailedRowsAmount] = useState<number>(0);

  useEffect(() => {
    recountAmountOfRowsForTabs();

    const throttledRecount = throttle(recountAmountOfRowsForTabs, 2000);

    const resizeObserver = new ResizeObserver((el) => {
      // recountAmountOfRowsForTabs();
      throttledRecount();
    });
    const heightDiv = document.querySelector('#heightControl');
    console.log('heightDiv', heightDiv);
    console.log('heightDiv', heightDiv?.clientHeight);
    if (!heightDiv) return;

    resizeObserver.observe(heightDiv);

    return () => {
      resizeObserver.unobserve(heightDiv);
    };
  }, []);

  const recountAmountOfRowsForTabs = () => {
    const beforeTable = 145;
    const afterTable = 84;
    const rowHeight = 45;

    let amountOfRowsForTable = Math.floor(
      (window.innerHeight - beforeTable - afterTable) / rowHeight
    );

    const beforeTabHeight = 24 + 28;
    const videoSlideSectionHeight = 212 + 20 + 12 + 16;
    const graphSectionHeight = 175 + 24 + 12;
    const detailedRowHeight = 37;

    const afterTabHeight = 24;

    let amountOfRowsForDetailedSection = Math.floor(
      (window.innerHeight -
        beforeTabHeight -
        videoSlideSectionHeight -
        afterTabHeight -
        graphSectionHeight) /
        detailedRowHeight
    );

    if (amountOfRowsForDetailedSection < 1) {
      amountOfRowsForDetailedSection = 1;
    }
    if (amountOfRowsForTable < 1) {
      amountOfRowsForTable = 1;
    }

    setTableRowsAmount(amountOfRowsForTable);
    setDetailedRowsAmount(amountOfRowsForDetailedSection);
  };

  return (
    <Box ml={4} h={'calc(100% - 30px)'}>
      <Flex h={'100%'}>
        {tableRowsAmount && <KeywordsDataSection amount={tableRowsAmount} />}
        {detailedRowsAmount && (
          <KeywordDetailedInfoSection amount={detailedRowsAmount} />
        )}
      </Flex>
    </Box>
  );
};
