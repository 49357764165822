import React, {useRef} from 'react';
import Spin from '@semcore/ui/spin';
import {Page} from '../../ui';
import Script from 'react-load-script';
import Api from '../../api/network';
import {useNavigate, useNavigation} from 'react-router-dom';
import {api, getExampleVideoKeywords} from '../../api/request';

const LoadingPage = () => {
  const navigation = useNavigate();
  const oldHeight = useRef(0);
  let attempts = 0;

  const redirectToStartPage = () => {
    navigation('/start', {replace: true});
  };

  const redirectToErrorPage = () => {
    navigation('/error', {replace: true});
  };

  const getToken = () => {
    api
      .getToken()
      .then(() => {
        getExampleVideoKeywords()
          .then((json) => {
            console.log(json);
            redirectToStartPage();
          })
          .catch((err) => {
            console.log(err);
            if (attempts > 3) {
              redirectToErrorPage();
            } else {
              attempts += 1;
              setTimeout(() => {
                console.log('reattempting to get token');
                getToken();
              }, 1000);
            }
          });
      })
      .catch((err) => {
        console.log('withMistake');
        setTimeout(() => {
          getToken();
        }, 2000);
        // redirectToErrorPage();
      });
  };

  const onLoad = () => {
    window?.SM?.init()
      .then(() => {
        getToken();
        // // window?.SM?.client('getPageInfo')
        // //   .then((json) => {
        // //     // window.SM.client('resizeWindow', { height: json.clientHeight });
        // //     setInterval(() => {
        // //       let clientHeight = json.clientHeight;
        // //       let height =
        // //         document.getElementById('heightControl')?.offsetHeight + 48 ||
        // //         0 + 20 + 48;
        // //       console.log('height', height);
        // //       if (clientHeight >= height && oldHeight.current !== height) {
        // //         oldHeight.current = height;
        // //         console.log('min');
        // //         window?.SM?.client('resizeWindow', {
        // //           height: clientHeight,
        // //           // width: 1138,
        // //         });
        // //       } else if (
        // //         clientHeight < height &&
        // //         oldHeight.current !== height
        // //       ) {
        // //         console.log('middle');
        // //         oldHeight.current = height;
        // //         window?.SM?.client('resizeWindow', {
        // //           height: height,
        // //           // width: 1138,
        // //         });
        // //       }
        // //     }, 1000);
        // //     getToken();
        // //     console.log('getPageInfo', json);
        //   //   })
        //     .catch((error) => {
        //       console.log('getPageInfo', error);
        //       redirectToErrorPage();
        //     });
      })
      .catch((error) => {
        console.log('initError', error);
        redirectToErrorPage();
      });
  };

  const onError = () => {};
  const onCreate = () => {};

  // render() {
  return (
    <Page style={{display: 'flex', justifyContent: 'center'}}>
      <Script
        url="https://static.semrush.com/app-center/sdk.js"
        onCreate={onCreate}
        onError={onError}
        onLoad={onLoad}
      />
      <Spin size={'xxl'} center />
    </Page>
  );
  // }
};

export default LoadingPage;
