import Spin from '@semcore/ui/spin';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Api from '../api/network';
import {api, getExampleVideoKeywords} from '../api/request';
import {Page} from '../ui';

type Props = {} & React.PropsWithChildren;
export const ProtectedRoute: React.FC<Props> = ({children}) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  let authRefreshAmount = 0;

  const isTest = false;

  const redirectToErrorPage = () => {
    navigation('/error', {replace: true});
  };
  const checkAuth = () => {
    if (isTest) return setLoading(false);
    // if (isFailureTest) return redirectToErrorPage();

    setLoading(true);

    getExampleVideoKeywords()
      .then((json) => {
        console.log(json);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        if (authRefreshAmount > 3) {
          redirectToErrorPage();
        } else {
          console.log('test');
          authRefreshAmount += 1;
          setTimeout(() => {
            window?.SM?.init()
              .then(() => {
                api?.getToken()?.finally(() => {
                  checkAuth();
                });
              })
              .catch(() => {
                redirectToErrorPage();
              });
          }, 500);
        }
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return loading ? (
    <Page style={{display: 'flex', justifyContent: 'center'}}>
      <Spin size={'xxl'} center />
    </Page>
  ) : (
    <>{children}</>
  );
};
