import Button from '@semcore/ui/button';
import {colors, Donut, Plot} from '@semcore/ui/d3-chart';
import {Box, Flex} from '@semcore/ui/flex-box';
import {List} from '@semcore/ui/typography';
import {Text} from '@semcore/typography';
import {useNavigate} from 'react-router-dom';
import {Image} from '../Image/Image';

interface Props {
  cardTitle: string;

  descriptionList: string[];
  // donutData: {[key: string]: number};
  cardNumber: number;
  cardColor: string;
  previewImg: string;
}

export const RowVideoCardItem: React.FC<Props> = ({
  cardTitle,
  descriptionList,
  previewImg,
  cardColor,
  cardNumber,
}) => {
  const navigation = useNavigate();
  const redirect = () => {
    try {
      localStorage?.removeItem('savedLastTab');
    } catch (err) {
      console.log(err);
    }
    navigation('/main', {replace: true, state: {chosenTab: cardNumber}});
  };
  return (
    <Box
      position={'relative'}
      style={{
        background: cardColor,
        overflow: 'hidden',
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
      w={0.3}
      h={610}
    >
      <div>
        <div
          style={{
            position: 'absolute',
            left: 30,
            top: 30,
            fontSize: 100,
            lineHeight: '100px',
            fontWeight: 700,
            color: 'white',
            zIndex: 0,
          }}
        >
          {cardNumber}
        </div>

        <Box
          mt={24}
          style={{paddingLeft: 30, paddingRight: 30, zIndex: 2}}
          position="relative"
        >
          <div
            style={{
              fontSize: 20,
              lineHeight: '24px',
              fontWeight: 700,
              zIndex: 2,
            }}
          >
            {cardTitle}
          </div>
          <List
            fontSize={16}
            fontWeight={400}
            lineHeight={'24px'}
            hMin={160}
            mb={5}
            mt={5}
          >
            {descriptionList.map((descriptionItem, index) => (
              <List.Item key={index + descriptionItem + 'descriptionItem'}>
                {descriptionItem}
              </List.Item>
            ))}
          </List>
          <Button use="primary" size="l" onClick={redirect}>
            <Button.Text>Get started</Button.Text>
          </Button>
        </Box>
      </div>

      <Flex w={'100%'} pl={'30px'}>
        <Image previewImg={previewImg} />
      </Flex>
    </Box>
  );
};
