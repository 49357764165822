import Divider from '@semcore/ui/divider';
import {Box, Flex} from '@semcore/ui/flex-box';
import {Text} from '@semcore/ui/typography';

interface Props {
  currentMoment: string;
  sinceMoment: string;
  currentValues: number;
  pastValues: number;
  differenceValue: number;
  differencePercent: string;
}

export const DifferenceTooltip: React.FC<Props> = ({
  currentMoment,
  sinceMoment,
  currentValues,
  pastValues,
  differencePercent,
  differenceValue,
}) => {
  return (
    <>
      <Flex
        direction="column"
        alignItems="space-between"
        justifyContent="space-evenly"
      >
        <Box width="100%">
          <Flex justifyContent={'space-between'} style={{width: '100%'}}>
            <Text>{`Current (${currentMoment})`}</Text>
            <Text bold>
              {Intl.NumberFormat('en', {
                compactDisplay: 'short',
                notation: 'compact',
              })?.format(currentValues || 0)}
            </Text>
          </Flex>
          <Flex my={2} justifyContent={'space-between'} style={{width: '100%'}}>
            <Text>{`Previous (${sinceMoment})`}</Text>
            <Text bold>
              {Intl.NumberFormat('en', {
                compactDisplay: 'short',
                notation: 'compact',
              })?.format(pastValues || 0)}
            </Text>
          </Flex>
          <Divider />
          <Flex my={2} justifyContent={'space-between'} style={{width: '100%'}}>
            {`Difference`}
            <Text
              style={
                differenceValue > 0
                  ? {color: 'green'}
                  : differenceValue < 0
                  ? {color: 'red'}
                  : {}
              }
            >
              {Intl.NumberFormat('en', {
                compactDisplay: 'short',
                notation: 'compact',
              })?.format(differenceValue || 0) + ` (${differencePercent}%)`}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
