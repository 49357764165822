import {Box, Flex} from '@semcore/ui/flex-box';
import {Error} from '@semcore/ui/widget-empty';
import {Page} from '../../ui';

export const ErrorPage = () => {
  return (
    <Page>
      <div id="heightControl">
        <div className="padding-holder">
          <Flex justifyContent={'center'} alignItems="center">
            <Error />
          </Flex>
        </div>
      </div>
    </Page>
  );
};
