import React, {useEffect, useState} from 'react';
import {Page} from '../../ui';
import {getExampleVideoKeywords} from '../../api/request';
import {Flex, Box} from '@semcore/ui/flex-box';

import {RowVideoCardItem} from '../../components/startScreen/RowVideoCardItem';

import TopKeywordPreviewImg from '../../assets/img/TopKeywordPreviewImg.png';
import MostViewedVideosPreviewImg from '../../assets/img/MostViewedVideosPreviewImg.png';
import FastGrowingKeywordPreviewImg from '../../assets/img/FastGrowingKeywordPreviewImg.png';
import Api from '../../api/network';
import {useNavigate} from 'react-router-dom';
import Spin from '@semcore/ui/spin';

const TITLE = 'Keywords Analytics for YouTube';
const DESCRIPTION =
  'Research keywords, discover trends insights and most viewed video on YouTube, all in one app.';

export const StartPage = () => {
  useEffect(() => {
    document.body.style.background = 'white';
    getExampleVideoKeywords().then((json) => {
      console.log(json);
    });
  }, []);

  return (
    <Page>
      <div id="heightControl">
        <div className="padding-holder">
          <Flex
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box my={6} style={{fontSize: 48, fontWeight: 700}}>
              {TITLE}
            </Box>
            <Box mb={6} style={{fontSize: 16}}>
              {DESCRIPTION}
            </Box>
          </Flex>

          <Flex
            direction={'row'}
            alignItems={'center'}
            justifyContent="space-between"
            mx={4}
          >
            <RowVideoCardItem
              cardTitle="Keywords Research"
              descriptionList={[
                'Discover keywords users are searching on YouTube',
                'Find out each keyword search volume and competitive rate',
                'Detect the most performing keywords',
              ]}
              previewImg={TopKeywordPreviewImg}
              cardNumber={1}
              cardColor={'#E9F7FF'}
            />
            <RowVideoCardItem
              cardTitle="Fast growing keywords"
              descriptionList={[
                'Get YouTube search trends',
                'Detect fast growing keywords on YouTube',
                'Check the most popular related videos',
              ]}
              previewImg={FastGrowingKeywordPreviewImg}
              cardNumber={2}
              cardColor={'#F3FFDB'}
            />
            <RowVideoCardItem
              cardTitle="Top Videos"
              descriptionList={[
                'Find out the most popular videos on YouTube',
                'Uncover top related keywords',
              ]}
              previewImg={MostViewedVideosPreviewImg}
              cardNumber={3}
              cardColor={'#F3E7FF'}
            />
          </Flex>
        </div>
      </div>
    </Page>
  );
};

export default StartPage;
