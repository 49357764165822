import {observable, action} from 'mobx';
import moment from 'moment';
import {
  FastGrowingKeywordListItem,
  KeywordListItem,
  TimePeriod,
} from '../api/request';
import {AvailableCountriesList} from '../types/enums';

class appStore {
  [key: string]: any;
  @observable currentActiveKeyword: KeywordListItem | null = null;

  @observable
  currentActiveFastGrowingKeyword: FastGrowingKeywordListItem | null = null;
  @observable chosenAggregationDate: TimePeriod = TimePeriod.month;
  @observable chosenFastGrowingAggregationDate: TimePeriod = TimePeriod.month;
  @observable chosenTopVideosAggregationDate: TimePeriod = TimePeriod.month;
  @observable maxSearchVolume: number = 0;
  @observable maxFastGrowingKeywordsSearchVolume: number = 0;

  @observable chosenCountry: keyof typeof AvailableCountriesList = 'USA';

  @action restoreSavedTabs = () => {
    try {
      const savedTopKeywordsTab = localStorage?.getItem(
        'chosenAggregationDate'
      ) as TimePeriod;
      const savedFastGrowingKeywordsTab = localStorage?.getItem(
        'chosenFastGrowingAggregationDate'
      ) as TimePeriod;
      const mostViewedVideosTab = localStorage?.getItem(
        'chosenTopVideosAggregationDate'
      ) as TimePeriod;

      if (savedTopKeywordsTab) {
        this.setChosenAggregationDate(savedTopKeywordsTab, 'top');
      }
      if (savedFastGrowingKeywordsTab) {
        this.setChosenAggregationDate(
          savedFastGrowingKeywordsTab,
          'fastGrowing'
        );
      }
      if (mostViewedVideosTab) {
        this.setChosenAggregationDate(mostViewedVideosTab, 'videos');
      }
    } catch (err) {
      console.log(err);
    }
  };

  @action setChosenAggregationDate = (
    newTimePeriod: TimePeriod,
    tab: 'top' | 'fastGrowing' | 'videos'
  ) => {
    switch (tab) {
      case 'top':
        this.chosenAggregationDate = newTimePeriod;
        try {
          localStorage?.setItem('chosenAggregationDate', newTimePeriod);
        } catch (err) {
          console.log(err);
        }
        break;
      case 'fastGrowing':
        this.chosenFastGrowingAggregationDate = newTimePeriod;
        try {
          localStorage?.setItem(
            'chosenFastGrowingAggregationDate',
            newTimePeriod
          );
        } catch (err) {
          console.log(err);
        }

        break;
      default:
        this.chosenTopVideosAggregationDate = newTimePeriod;
        try {
          localStorage?.setItem(
            'chosenTopVideosAggregationDate',
            newTimePeriod
          );
        } catch (err) {
          console.log(err);
        }
    }
  };

  @action setCurrentActiveKeyword = (keyword: KeywordListItem) => {
    console.log('function in store', keyword);
    this.currentActiveKeyword = keyword;
  };
  // FIXME: ANY
  @action setCurrentActiveFastGrowingKeyword = (
    keyword: KeywordListItem | any
  ) => {
    this.currentActiveFastGrowingKeyword = keyword;
  };
  @action setChosenCountry = (country: keyof typeof AvailableCountriesList) => {
    this.chosenCountry = country;
    try {
      localStorage?.setItem('chosenCountry', country);
    } catch (err) {
      console.log(err);
    }
  };

  @action findMaximumSearchVolume = (keywordsArray: KeywordListItem[]) => {
    const maxSearchInArray = Math.max(
      ...keywordsArray.map((keyword) => keyword.searches)
    );
    if (maxSearchInArray > this.maxSearchVolume) {
      this.maxSearchVolume = maxSearchInArray;
    }
  };

  @action clearMaximumSearchVolume = () => {
    this.maxSearchVolume = 0;
    this.maxFastGrowingKeywordsSearchVolume = 0;
  };

  @action findMaximumSearchVolumeForFastGrowingKeywords = (
    keywordsArray: FastGrowingKeywordListItem[]
  ) => {
    const maxSearchInArray = Math.max(
      ...keywordsArray.map((keyword) => keyword.counts)
    );
    if (maxSearchInArray > this.maxFastGrowingKeywordsSearchVolume) {
      this.maxFastGrowingKeywordsSearchVolume = maxSearchInArray;
    }
  };
}
export default new appStore();
