import {
  colors,
  Donut,
  Plot,
  Tooltip as ChartTooltip,
} from '@semcore/ui/d3-chart';
import {Box, Flex} from '@semcore/ui/flex-box';
import Skeleton, {PieChartSkeleton} from '@semcore/ui/skeleton';
import {Text} from '@semcore/ui/typography';
import {NoData} from '@semcore/ui/widget-empty';
import React, {useEffect, useState} from 'react';
import Tooltip from '@semcore/ui/tooltip';

interface Props {
  donutData: {[key: string]: number};
  donutColor: string;
  statsValue: number;
  statsText: 'High' | 'Low' | 'Medium';
  title: string;
  tooltipText: string;
  minScaleValue?: number;
  maxScaleValue?: string | number;
  showScales: boolean;
  additionalLeftOffset?: boolean;
}
export const InfoCardItem: React.FC<Props> = ({
  title,
  donutData,
  donutColor,
  tooltipText,
  statsText,
  statsValue,
  minScaleValue,
  maxScaleValue,
  showScales = false,
  additionalLeftOffset,
}) => {
  const [donutDataState, setDonutDataState] = useState<{[key: string]: number}>(
    {a: 0, b: 100}
  );

  useEffect(() => {
    console.log('donutData', donutData);
    setDonutDataState(donutData);
  }, [donutData]);
  return (
    <Box
      className="shadow-border full-width"
      my={3}
      position="relative"
      style={{
        display: 'border-box',
        background: 'white',
        borderRadius: 4,
        paddingTop: 16,
        paddingBottom: 16,
      }}
      w={230}
      px={5}
    >
      <Text fontSize={'14px'} lineHeight={'20px'}>
        {title}
      </Text>
      <Flex
        direction="row"
        alignItems="center"
        gap={5}
        style={{position: 'relative'}}
        className="InfoCardItem_flex_wrapper"
      >
        {showScales ? (
          <Box position="absolute" bottom={-0}>
            <Text>{minScaleValue}</Text>
          </Box>
        ) : null}
        {showScales ? (
          <Box
            position="absolute"
            bottom={-0}
            // right={'24%'}
            className={`InfoCardItem_max_number ${
              additionalLeftOffset ? 'additional_right' : ''
            }`}
          >
            <Text>{maxScaleValue}</Text>
          </Box>
        ) : null}

        {donutDataState.a && donutDataState.b ? (
          <Plot
            data={donutDataState}
            width={130}
            height={70}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <Donut halfsize outerRadius={55} innerRadius={30}>
              <Donut.Pie
                dataKey="a"
                color={donutColor || colors['orange400']}
                name="123"
              ></Donut.Pie>
              <Donut.Pie dataKey="b" color={colors['gray-200']}></Donut.Pie>
            </Donut>
            <ChartTooltip style={{maxWidth: 200}}>
              {({dataKey, name}) => {
                return {
                  children: (
                    <>
                      {/* <ChartTooltip.Title>{name as string}</ChartTooltip.Title> */}
                      <Flex justifyContent="space-between">
                        <Text>{tooltipText}</Text>
                      </Flex>
                    </>
                  ),
                };
              }}
            </ChartTooltip>
          </Plot>
        ) : (
          <Skeleton h={60} w={130}>
            <PieChartSkeleton viewBox="0 0 180 90" height="60" width="120" />
          </Skeleton>
        )}

        <Flex justifyContent="flex-end">
          <Tooltip>
            <Tooltip.Trigger>
              <Flex
                direction="column"
                justifyContent="flex-end"
                alignContent="flex-end"
                alignItems="end"
              >
                <Text fontSize={'24px'} lineHeight={'30px'} fontWeight={700}>
                  {Intl.NumberFormat('en', {
                    compactDisplay: 'short',
                    notation: 'compact',
                  })?.format(statsValue)}
                </Text>
                <Text fontSize={'12px'} lineHeight={'15px'}>
                  {statsText}
                </Text>
              </Flex>
            </Tooltip.Trigger>
            <Tooltip.Popper>
              <Flex justifyContent="space-between">
                <Text>{tooltipText}</Text>
              </Flex>
            </Tooltip.Popper>
          </Tooltip>
        </Flex>
      </Flex>
    </Box>
  );
};
