import {Box, Flex} from '@semcore/ui/flex-box';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ArrowLeft, ArrowRight} from '../../assets/img';
import {Text} from '@semcore/ui/typography';
import Link from '@semcore/ui/link';
import Tooltip from '@semcore/ui/tooltip';
import {useState} from 'react';

import {Mousewheel, Scrollbar, Navigation} from 'swiper';
import {Swiper as SwiperType} from 'swiper/types';
import {YoutubeChannelItem, YoutubeVideoInfoItem} from '../../api/request';
import moment from 'moment';
import {convertNumberToShortString} from '../../utils/helpers';

import style from './style.module.scss';

interface Props {
  videosData: YoutubeVideoInfoItem[];
  channelsData: YoutubeChannelItem[];
}
export const VideoSwiperSection: React.FC<Props> = ({
  videosData,
  channelsData,
}) => {
  const [activeSwiperIndex, setActiveSwiperIndex] = useState<number>(0);
  const [leftArrowActive, setLeftArrowActive] = useState<boolean>(false);
  const [rightArrowActive, setRightArrowActive] = useState<boolean>(true);

  const handleIndexChange = (swiper: SwiperType) => {
    console.log(swiper);
    setActiveSwiperIndex(swiper.activeIndex);
    setLeftArrowActive(!swiper.isBeginning);
    setRightArrowActive(!swiper.isEnd);
  };

  const handleVideoClick = (id: string) => {
    window.open(`https://www.youtube.com/watch?v=${id}`, '__blank');
  };

  return (
    <>
      <Box mb={2}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize={'16px'} lineHeight={'24px'} fontWeight={700}>
            Top popular video
          </Text>
          <Box className="arrowBox">
            <Flex>
              <Link
                disabled={!leftArrowActive}
                style={{marginRight: 20}}
                className={'arrowWrapperLink'}
              >
                <ArrowLeft fill={'#333333'} className="arrow_left_class" />
              </Link>
              <Link disabled={!rightArrowActive} className={'arrowWrapperLink'}>
                <ArrowRight
                  fill={rightArrowActive ? '#333333' : 'lightgray'}
                  className="arrow_right_class"
                />
              </Link>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Swiper
        direction={'horizontal'}
        // slidesPerView={3.5}
        slidesPerView={'auto'}
        spaceBetween={15}
        mousewheel={true}
        navigation={{
          nextEl: '.arrow_right_class',
          prevEl: '.arrow_left_class',
        }}
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: true,
        }}
        modules={[Mousewheel, Navigation, Scrollbar]}
        style={{height: 180, maxWidth: 553}}
        className="mySwiper"
        onActiveIndexChange={handleIndexChange}
      >
        {videosData.map((videoInfo, index) => {
          const videoOwnersChannel = channelsData?.find(
            (channel) => channel.id === videoInfo.snippet.channelId
          );
          console.log('videoInfo', videoInfo);
          if (!videoOwnersChannel) return null;

          return (
            // <SwiperSlide style={{width: 164}}>
            <SwiperSlide style={{width: 164}}>
              <Tooltip>
                <Tooltip.Popper>
                  <span style={{fontWeight: 700}}>
                    {videoInfo.snippet.title}
                  </span>
                  <br />
                  <span>{`${
                    videoInfo.snippet.channelTitle
                  } / ${convertNumberToShortString(
                    videoOwnersChannel?.statistics.subscriberCount
                  )} subscribers`}</span>
                </Tooltip.Popper>
                <Tooltip.Trigger>
                  <Box
                    // w={164}
                    onClick={() => handleVideoClick(videoInfo.id)}
                    style={{cursor: 'pointer'}}
                  >
                    <Flex
                      direction="column"
                      justifyContent="space-between"
                      alignItems="stretch"
                    >
                      <div
                        key={`slide-image-${videoInfo.id}`}
                        style={{
                          background: `url('${videoInfo.snippet.thumbnails.medium.url}')`,
                          backgroundColor: 'gray',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          borderRadius: 12,
                          marginBottom: 8,
                          width: '100%',
                          height: 96,
                        }}
                      />
                      <Text className="line-clamp">
                        {videoInfo.snippet.title}
                      </Text>
                      <Text color="#828282" style={{fontSize: 12}}>{`${
                        convertNumberToShortString(
                          videoInfo.statistics.viewCount
                        ) + ' views '
                      } ${moment(
                        videoInfo.snippet.publishedAt
                      ).fromNow()}`}</Text>
                    </Flex>
                  </Box>
                </Tooltip.Trigger>
              </Tooltip>
            </SwiperSlide>
          );
        })}
        <div
          className="swiper-scrollbar grabbable"
          style={{marginTop: 16}}
        ></div>
      </Swiper>
    </>
  );
};
