interface Props {
  previewImg: string;
}

export const Image: React.FC<Props> = ({previewImg}) => {
  return (
    <div
      style={{
        background: `url("${previewImg}")`,
        borderTopLeftRadius: '15px',
        height: 220,
        width: '100%',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};
