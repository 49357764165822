import React, {Component} from 'react';
import {Flex, Box} from '@semcore/ui/flex-box';
import {Text} from '@semcore/ui/typography';
import Divider from '@semcore/ui/divider';
import moment from 'moment';
import Skeleton from '@semcore/ui/skeleton';
// import {KeywordDataType} from '../pages/retailKeywordsTab/RetailKeywordsTab';
import Tooltip from '@semcore/ui/tooltip';
import {gaussRound, roundThousand} from '../utils/helpers';

type StatisticItemType = {
  loading: boolean;
  title: string;
  date: string;
  currentValue: number;
  oldValue: number;
  monthBefore: number;
  conversions?: boolean;
};

const StatisticItem = ({
  loading,
  title,
  date,
  currentValue,
  oldValue,
  monthBefore,
  conversions,
}: StatisticItemType) => {
  return (
    <Flex direction={'column'} pr={'24px'}>
      <Text lineHeight={'20px'} fontSize={'14px'} fontWeight={400}>
        {title}
      </Text>
      {!loading && (
        <React.Fragment>
          <Text
            hMin={'18px'}
            lineHeight={'18px'}
            fontSize={'12px'}
            fontWeight={400}
            color={'gray60'}
          >
            {date}
          </Text>
          <Text
            lineHeight={'28px'}
            fontSize={'28px'}
            fontWeight={700}
            mt={'4px'}
          >
            {`${roundThousand(currentValue)} ${conversions ? '%' : ''}`}
            <Tooltip>
              <Tooltip.Trigger>
                <Text
                  color={oldValue < 0 ? '#ED0006' : '#77AB38'}
                  fontSize={'12px'}
                  fontWeight={400}
                  ml={'8px'}
                >
                  {gaussRound(oldValue * 100, 1) + '%'}
                </Text>
              </Tooltip.Trigger>
              <Tooltip.Popper>
                <span>{`Current: ${roundThousand(currentValue)}${
                  conversions ? '%' : ''
                } (total for ${date})`}</span>
                <br />
                <span>{`Previous: ${roundThousand(monthBefore)}${
                  conversions ? '%' : ''
                } (total for ${date})`}</span>
              </Tooltip.Popper>
            </Tooltip>
          </Text>
        </React.Fragment>
      )}
    </Flex>
  );
};

const Sceleton = () => {
  return (
    <Flex>
      <Flex direction={'column'} pr={'24px'} hMax={76}>
        <Skeleton hMax={76} w={'110px'}>
          <Skeleton.Text y={0} />
          <Skeleton.Text y={45} />
          <Skeleton.Text y={60} />
        </Skeleton>
      </Flex>
      <Divider orientation="vertical" mr={'24px'} />
      <Flex direction={'column'} pr={'24px'}>
        <Skeleton hMax={76} w={'110px'}>
          <Skeleton.Text y={0} />
          <Skeleton.Text y={45} />
          <Skeleton.Text y={60} />
        </Skeleton>
      </Flex>
      <Divider orientation="vertical" mr={'24px'} />
      <Flex direction={'column'} pr={'24px'}>
        <Skeleton hMax={76} w={'110px'}>
          <Skeleton.Text y={0} />
          <Skeleton.Text y={45} />
          <Skeleton.Text y={60} />
        </Skeleton>
      </Flex>
      <Divider orientation="vertical" mr={'24px'} />
      <Flex direction={'column'} pr={'24px'}>
        <Skeleton hMax={76} w={'110px'}>
          <Skeleton.Text y={0} />
          <Skeleton.Text y={45} />
          <Skeleton.Text y={60} />
        </Skeleton>
      </Flex>
    </Flex>
  );
};

interface IProps {
  // keywordData?: KeywordDataType;
  keywordData?: any;
  loading: boolean;
}

export default class KeywordStatistic extends Component<IProps> {
  converDateToString = (value: string) => {
    return moment(value, 'YYYY-MM').format('MMMM YYYY');
  };

  render() {
    const {keywordData, loading} = this.props;
    return (
      <Box m={'28px 32px 0px 0px'}>
        {loading || keywordData == undefined ? (
          <Sceleton />
        ) : (
          <Flex>
            <StatisticItem
              loading={loading}
              title={'Search Requests'}
              date={this.converDateToString(keywordData?.date_agregation)}
              currentValue={keywordData?.searches}
              oldValue={keywordData.searches_to_old}
              monthBefore={keywordData.searches_month_before}
            />
            <Divider orientation="vertical" mr={'24px'} />
            <StatisticItem
              loading={loading}
              title={'Product Clicks'}
              date={this.converDateToString(keywordData?.date_agregation)}
              currentValue={keywordData?.clicks}
              oldValue={keywordData.clicks_to_old}
              monthBefore={keywordData.clicks_month_before}
            />
            <Divider orientation="vertical" mr={'24px'} />
            {/* <StatisticItem
                                loading={loading}
                                title={'‘Add to basket’ actions'}
                                date={this.converDateToString(keywordData?.date_agregation)}
                                currentValue={keywordData?.baskets}
                                oldValue={keywordData.baskets_to_old}
                                monthBefore={keywordData.baskets_month_before}
                                />
                            <Divider orientation="vertical" mr={'24px'} /> */}
            <StatisticItem
              loading={loading}
              title={'Orders'}
              date={this.converDateToString(keywordData?.date_agregation)}
              currentValue={keywordData?.orders}
              oldValue={keywordData.orders_to_old}
              monthBefore={keywordData.orders_month_before}
            />
            <Divider orientation="vertical" mr={'24px'} />
            <StatisticItem
              loading={loading}
              title={'Conversions'}
              conversions={true}
              date={this.converDateToString(keywordData?.date_agregation)}
              currentValue={
                keywordData?.clicks != 0
                  ? gaussRound(
                      (keywordData?.orders / keywordData?.clicks) * 100,
                      1
                    )
                  : 0
              }
              oldValue={
                keywordData?.clicks != 0 && keywordData.clicks_month_before != 0
                  ? keywordData.orders_month_before /
                      keywordData.clicks_month_before -
                    keywordData?.orders / keywordData?.clicks
                  : 0
              }
              monthBefore={
                keywordData.clicks_month_before != 0
                  ? gaussRound(
                      (keywordData.orders_month_before /
                        keywordData.clicks_month_before) *
                        100,
                      1
                    )
                  : 0
              }
            />
          </Flex>
        )}
      </Box>
    );
  }
}
