import React, {Component, useEffect, useRef, useState} from 'react';
import {GlobalNotice, Page} from '../../ui';
import {Flex} from '@semcore/ui/flex-box';
import TabLine from '@semcore/ui/tab-line';
import {MostViewedVideosTab, TopKeywordsTab} from '../tabs';
import {FastGrowingKeywordsTab} from '../tabs/FastGrowingKeywordsTab/FastGrowingKeywordsTab';
import {CountrySelect} from '../../components/CountrySelect/CountrySelect';
import {useLocation} from 'react-router-dom';
import store from '../../stores/store';

type State = {
  tab: number;
};

const MainPage = () => {
  const historyTab = useLocation();
  const [activeTab, setActiveTab] = useState<number>(1);

  const noticeRef = useRef<any>(null);

  useEffect(() => {
    document.body.style.background = 'rgb(244, 245, 249)';
    let lastSavedTab: string | null = '';

    noticeRef.current.open();

    try {
      lastSavedTab = localStorage?.getItem('savedLastTab');
    } catch (err) {
      console.log(err);
    }

    if (lastSavedTab) {
      setActiveTab(+lastSavedTab);
    }
    // history has lower priority than saved tab from LocalStorage
    else if (historyTab?.state?.chosenTab) {
      changeTab(historyTab?.state?.chosenTab);
    }
  }, []);

  const changeTab = (tab: number) => {
    setActiveTab(tab);
    try {
      localStorage?.setItem('savedLastTab', String(tab));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Page style={{background: '#F4F5F9'}}>
      <GlobalNotice
        text={
          'With our latest app update, we have improved the precision of traffic estimation. As a result, you may notice changes in the absolute numbers from previous months. However, all trends and percentage changes have remained consistent and intact. This update enhances data accuracy without altering the overall patterns.'
        }
        ref={noticeRef}
      />
      <div id={'heightControl'}>
        <div className="padding-holder">
          <TabLine
            onChange={changeTab}
            value={activeTab}
            className="control-line"
          >
            <Flex
              alignItems={'center'}
              justifyContent="space-between"
              w={'100%'}
            >
              <div>
                <TabLine.Item value={1} ml={4} mr={4} pb={2}>
                  <span style={{fontSize: 16, lineHeight: '24px'}}>
                    {'Top  Keywords'}
                  </span>
                </TabLine.Item>
                <TabLine.Item value={2} mr={4} pb={2}>
                  <span style={{fontSize: 16, lineHeight: '24px'}}>
                    {'Fast growing keywords'}
                  </span>
                </TabLine.Item>
                <TabLine.Item value={3} mr={4} pb={2}>
                  <span style={{fontSize: 16, lineHeight: '24px'}}>
                    {'Most viewed videos'}
                  </span>
                </TabLine.Item>
              </div>
              <div style={{paddingBottom: 6, justifySelf: 'flex-end'}}>
                <CountrySelect />
              </div>
            </Flex>
          </TabLine>
          {activeTab === 1 && <TopKeywordsTab />}
          {activeTab === 2 && <FastGrowingKeywordsTab />}
          {activeTab === 3 && <MostViewedVideosTab />}
        </div>
      </div>
    </Page>
  );
  // }
};

export default MainPage;
