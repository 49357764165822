import DataTable, {DataTableData, DataTableSort} from '@semcore/ui/data-table';
import {Box, Flex} from '@semcore/ui/flex-box';
import Pagination from '@semcore/ui/pagination';
import Pills from '@semcore/ui/pills';
import Tooltip from '@semcore/ui/tooltip';

import {observer} from 'mobx-react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
  getKeywordsList,
  GetSearchKeywordList,
  getTopKeywordsList,
  getTopKeywordsListRequest,
  KeywordListItem,
  TimePeriod,
} from '../../../api/request';

import store from '../../../stores/store';
import style from './style.module.scss';

import SpinContainer from '@semcore/spin-container';

import {NoData} from '@semcore/ui/widget-empty';
import {
  convertTimePeriodToPillValue,
  getCompetitiveRateText,
  getTooltipTextByCompetitiveText,
  recountSearchVolume,
} from '../../../utils/helpers';
import {CompetitiveRateCircle} from '../../../components/CompetitiveRateCircle';
import Ellipsis from '@semcore/ui/ellipsis';
import {SearchInput} from '../../../components/SearchInput/SearchInput';

interface Props {
  amount: number;
}
export const KeywordsDataSection: React.FC<Props> = observer((props) => {
  const [keyword, setKeyword] = useState<string>('');

  const [pillValue, setPillValue] = useState<number>(
    convertTimePeriodToPillValue(store.chosenAggregationDate)
  );
  const [previousPillValue, setPreviousPillValue] = useState<number>(
    convertTimePeriodToPillValue(store.chosenAggregationDate)
  );
  const [tableSort, setTableSort] = useState<DataTableSort & string[]>([
    'searches',
    'desc',
  ]);
  const [tableData, setTableData] = useState<KeywordListItem[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const [activeRowItem, setActiveRowItem] = useState<KeywordListItem>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const manualSearchFlag = useRef<boolean>(false);

  // const [perPageAmount, setPerPageAmount] = useState<number>(11);
  // const perPage = useRef<number>(11);

  // recount amount of rows in table on window resize
  // useEffect(() => {
  //   window.addEventListener('resize', recountAmountOfRowsInTable);

  //   return () =>
  //     window.removeEventListener('resize', recountAmountOfRowsInTable);
  // }, []);

  // // initial amount of rows
  // useEffect(() => {
  //   recountAmountOfRowsInTable();

  //   if (store.chosenAggregationDate) {
  //     setPillValue(convertTimePeriodToPillValue(store.chosenAggregationDate));
  //   }
  // }, []);

  // const recountAmountOfRowsInTable = () => {
  //   const beforeTable = 145;
  //   const afterTable = 84;
  //   const sizeOfTableRow = 45;

  //   const amount = Math.floor(
  //     (window.innerHeight - beforeTable - afterTable) / sizeOfTableRow
  //   );

  //   if (amount !== perPage.current) {
  //     perPage.current = amount > 12 ? 12 : amount;
  //     setPerPageAmount(perPage.current);
  //   }
  // };

  useEffect(() => {
    if (store.chosenAggregationDate) {
      setPillValue(convertTimePeriodToPillValue(store.chosenAggregationDate));
    }
  }, []);

  // useEffect(() => {
  //   perPage.current = props.amount;
  //   setPerPageAmount(props.amount);
  // }, [props.amount]);

  // 2 useEffects, because the server returns pagination only for top keywords
  // and all the keywords for the request by a keyword
  useEffect(() => {
    handleSearch();
  }, [
    pillValue,
    props.amount,
    // perPageAmount,
    JSON.stringify(store.chosenCountry),
    tableSort[0],
    tableSort[1],
  ]);

  useEffect(() => {
    if (manualSearchFlag.current) return;

    handleSearch();
  }, [currentPage]);

  const handleSearch = () => {
    if (manualSearchFlag.current) {
      console.log('keyword will handleKeyword search', keyword);
      handleKeywordSearch();
    } else {
      console.log('keyword will handleTopKeyword search', keyword);
      handleTopKeywordSearch();
    }
  };

  const enterKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  const handleTopKeywordSearch = () => {
    const timePeriod =
      pillValue === 0
        ? TimePeriod.day
        : pillValue === 1
        ? TimePeriod.week
        : TimePeriod.month;

    const topKeywordSearchObject: getTopKeywordsListRequest = {
      period: timePeriod,
      // sort_order: tableSort?.[1],
      // per_page: perPage.current,
      per_page: props.amount,
      page: currentPage,
      country: store.chosenCountry,
      // TODO: will be changed to .map(item => item.join(' ')).join(',') in the future
      order_by: tableSort.join(' '),
    };
    setLoading(true);
    getTopKeywordsList(topKeywordSearchObject)
      .then((json) => {
        console.log('getTopKeywordListSuccess', json);
        setTableData(json.keyword_data);
        store.setCurrentActiveKeyword(json.keyword_data?.[0]);
        store.findMaximumSearchVolume(json.keyword_data);
        // setTotalPages(Math.ceil(json.meta.total / perPage.current));
        setTotalPages(Math.ceil(json.meta.total / props.amount));

        if (previousPillValue !== pillValue) {
          setPreviousPillValue(pillValue);
          recountSearchVolume(json.keyword_data, 'topKeywords');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeywordSearch = (specificKeyword?: string) => {
    if (!specificKeyword && !keyword) {
      handleTopKeywordSearch();
      return;
    }
    const timePeriod =
      pillValue === 0
        ? TimePeriod.day
        : pillValue === 1
        ? TimePeriod.week
        : TimePeriod.month;
    const keywordToSearch = specificKeyword ?? keyword;
    setLoading(true);
    const searchObj: GetSearchKeywordList = {
      searchKeyword: keywordToSearch,
      country: store.chosenCountry,
      period: timePeriod,
      // TODO: the same thing as with getTopKeywordList function
      order_by: tableSort.join(' '),
      // per_page: perPage.current,
      per_page: props.amount,
      page: currentPage,
    };
    getKeywordsList(searchObj)
      .then((json) => {
        console.log('json in getKeywordsListSuccess', json);
        setTableData(json);
        store.setCurrentActiveKeyword(json[0]);
        setTotalPages(0);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const changePillValue = (pillValue: number) => {
    setPillValue(pillValue);
    const timePeriod =
      pillValue === 0
        ? TimePeriod.day
        : pillValue === 1
        ? TimePeriod.week
        : TimePeriod.month;

    store.setChosenAggregationDate(timePeriod, 'top');
  };

  const handleSearchButtonClick = () => {
    setLoading(true);
    setTimeout(() => {
      if (keyword?.length > 0) {
        manualSearchFlag.current = true;
      } else {
        manualSearchFlag.current = false;
      }

      if (currentPage > 1) {
        setCurrentPage(1);
        setTimeout(() => {
          handleKeywordSearch();
        }, 0);
      } else {
        handleKeywordSearch();
      }
    });
  };

  const handleSearchbarKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <Box
      w="50%"
      pr={5}
      pt={5}
      style={{borderRight: '1px solid #C4C7CF'}}
      pb={6}
      // h={'100%'}
    >
      <Flex justifyContent="space-between">
        <SearchInput
          handleKeyboardPress={handleSearchbarKeyPress}
          handleSearchButtonPress={handleSearchButtonClick}
          handleSearchInputAction={enterKeyword}
          currentSearchValue={keyword}
        />

        <Box className={style.pill}>
          <Pills
            size="m"
            value={pillValue}
            onChange={changePillValue}
            className={style.pillTab}
          >
            <Pills.Item value={0} tabIndex={0} neighborLocation="right">
              <Pills.Item.Text>Last Day</Pills.Item.Text>
            </Pills.Item>
            <Pills.Item value={1} tabIndex={1} neighborLocation="both">
              Last Week
            </Pills.Item>
            <Pills.Item value={2} tabIndex={2} neighborLocation="left">
              Last Month
            </Pills.Item>
          </Pills>
        </Box>
      </Flex>

      <Box
        style={{background: 'white', borderRadius: 6}}
        mt={3}
        className="shadow-border"
      >
        <Flex direction="column" justifyContent="space-between">
          <DataTable
            data={
              manualSearchFlag.current
                ? tableData?.slice(
                    // (currentPage - 1) * perPageAmount,
                    (currentPage - 1) * props.amount,
                    // perPageAmount * currentPage
                    props.amount * currentPage
                  )
                : tableData || []
            }
            // sort={tableSort}
            // onSortChange={setTableSort}
            use="primary"
          >
            <DataTable.Head style={{backgroundColor: 'white'}} use="secondary">
              <DataTable.Column
                name="keyword"
                children="Keyword Name"
                flex={2}
                // sortable
              />
              <DataTable.Column
                name="searches"
                children="Search Volume"
                flex={1}
                justifyContent="end"
                sortable
              />
              <DataTable.Column
                flex={1}
                justifyContent="end"
                name="rate"
                children="Competitive rate"
                // sortable
                style={{whiteSpace: 'nowrap'}}
              />
            </DataTable.Head>
            {!loading && (!tableData || tableData.length === 0) && (
              <NoData h={400} />
            )}
            <DataTable.Body
              style={loading ? {minHeight: 400} : {}}
              tag={SpinContainer}
              loading={loading}
            >
              {' '}
              <DataTable.Row>
                {(props, row, index) => {
                  return {
                    style: {
                      outerHeight: 44,
                      cursor: 'pointer',
                    },
                    onClick: () => {
                      store.setCurrentActiveKeyword(row as KeywordListItem);
                      setActiveRowItem(row as KeywordListItem);
                    },
                    active: store.currentActiveKeyword?.keyword === row.keyword,

                    children: props.children,
                  };
                }}
              </DataTable.Row>
              <DataTable.Cell name="keyword">
                {(props: any, row: DataTableData, index: number) => {
                  return {
                    style: {
                      alignItems: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                    children: (
                      <Ellipsis
                        maxLine={1}
                        tooltip={
                          (props.children.props.children + '').length > 32
                        }
                        trim="end"
                        containerRect={{width: 156}}
                      >
                        {props.children.props.children}
                      </Ellipsis>
                    ),
                  };
                }}
              </DataTable.Cell>
              <DataTable.Cell name="searches">
                {(props: any, row: DataTableData, index: number) => {
                  return {
                    children: Intl.NumberFormat('en', {
                      compactDisplay: 'short',
                      notation: 'compact',
                    })?.format(props.children.props.children || 0),
                  };
                }}
              </DataTable.Cell>
              <DataTable.Cell name="rate">
                {(props, row, index) => {
                  const status = getCompetitiveRateText(
                    (row?.rate as number) || 0
                  );
                  const tooltipText = getTooltipTextByCompetitiveText(
                    status,
                    'top'
                  );

                  return {
                    children: (
                      <Tooltip title={tooltipText}>
                        <Flex alignItems="center">
                          <Box mr={'6px'}>{props.children}</Box>

                          <CompetitiveRateCircle
                            rate={(row?.rate as number) || 0}
                          />
                        </Flex>
                      </Tooltip>
                    ),
                  };
                }}
              </DataTable.Cell>
            </DataTable.Body>
          </DataTable>

          <Pagination
            className={style.pagination__input__withPadding}
            style={{justifyContent: 'flex-end'}}
            mt={4}
            mr={4}
            mb={4}
            currentPage={currentPage}
            onCurrentPageChange={changePage}
            totalPages={
              // totalPages || Math.ceil(tableData.length / perPage.current) || 1
              totalPages || Math.ceil(tableData.length / props.amount) || 1
            }
            pl={1}
          />
        </Flex>
      </Box>
    </Box>
  );
});
