import {Box, Flex} from '@semcore/ui/flex-box';
import Pagination from '@semcore/ui/pagination';
import Pills from '@semcore/ui/pills';
import Select from '@semcore/ui/select';
import Spin from '@semcore/ui/spin';
import {Text} from '@semcore/ui/typography';
import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Api from '../../../api/network';
import {
  getChannelInfo,
  getExampleVideoKeywords,
  getTopVideos,
  TimePeriod,
  TopVideoType,
  YoutubeChannelItem,
} from '../../../api/request';
import {TopVideoCard} from '../../../components/TopVideoCard/TopVideoCard';
import store from '../../../stores/store';
import {StoredKeywordItemValue} from '../../../types/entity';
import {
  convertTimePeriodToPillValue,
  getLocallyStoredData,
  setLocallyStoredData,
} from '../../../utils/helpers';

import style from './style.module.scss';

const offsetSize = 50;

type CombinedVideoType = {
  video: TopVideoType;
  ownerChannel: YoutubeChannelItem;
};

export const MostViewedVideosTab = observer(() => {
  const [topVideosInfo, setTopVideosInfo] = useState<TopVideoType[]>([]);
  const [videosList, setVideosList] = useState<CombinedVideoType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [channelsLoading, setChannelsLoading] = useState<boolean>(true);

  const [pillValue, setPillValue] = useState<number>(
    convertTimePeriodToPillValue(store.chosenTopVideosAggregationDate)
  );

  const [channelsArray, setChannelsArray] = useState<YoutubeChannelItem[]>([]);

  const timePeriodFromStore = store.chosenTopVideosAggregationDate;

  const countryInStore = JSON.stringify(store.chosenCountry);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(6);

  const updateCurrentPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const changePerPageAmount = (amount: number) => {
    setCurrentPage(1);
    setPerPage(amount);
  };

  useEffect(() => {
    setCurrentPage(1);

    const timePeriod =
      pillValue === 0
        ? TimePeriod.day
        : pillValue === 1
        ? TimePeriod.week
        : TimePeriod.month;

    if (timePeriod !== timePeriodFromStore) {
      store.setChosenAggregationDate(timePeriod, 'videos');
    }

    setLoading(true);
    getTopVideos(timePeriod, store.chosenCountry)
      .then((json) => {
        const storedVideosData = getLocallyStoredData(
          `mostViewedVideos_${timePeriodFromStore}`
        );
        console.log('storedVideosData', storedVideosData);
        if (storedVideosData?.channelsInfoArray) {
          console.log('!!!!!!!', storedVideosData.channelsInfoArray);
          setChannelsArray(storedVideosData.channelsInfoArray);

          recountVideosList(
            json.keyword_data,
            storedVideosData.channelsInfoArray
          );

          setLoading(false);
          setChannelsLoading(false);
          return;
        } else {
          getTopViewedChannelsInfo(json.keyword_data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pillValue, countryInStore]);

  const recountVideosList = (
    videosInfo: TopVideoType[],
    channelsArray: YoutubeChannelItem[]
  ) => {
    const amountOfShowableVideos = Math.floor(videosInfo.length / 3) * 3;
    setTopVideosInfo(videosInfo.slice(0, amountOfShowableVideos));

    const resultArray = videosInfo
      .slice(0, amountOfShowableVideos)
      .sort((a, b) => {
        return b.num_views - a.num_views;
      })
      .reduce<CombinedVideoType[]>((acc, video, index) => {
        const ownerChannel = channelsArray.find(
          (channel) => channel.id === video.channel_id
        );
        if (ownerChannel) {
          acc.push({video: video, ownerChannel: ownerChannel});
        }
        return acc;
      }, []);

    setVideosList(resultArray);
  };

  const getTopViewedChannelsInfo = (topVideosData: TopVideoType[]) => {
    setChannelsLoading(true);

    const videoChannelsId = topVideosData.map((video) => video.channel_id);
    console.log('ids', videoChannelsId);
    // .slice(0, 60);
    return Promise.all([
      getChannelInfo(videoChannelsId.slice(0, offsetSize)),
      getChannelInfo(videoChannelsId.slice(offsetSize * 1 + 1, offsetSize * 2)),
    ])
      .then((responses) => {
        const combinedInfo = responses.map((response) => {
          const channelInfo = response.items;
          const channelNeededInfo = channelInfo.reduce((acc, item, index) => {
            acc.push({
              id: item.id,
              statistics: {
                subscriberCount: item.statistics.subscriberCount,
              },
              snippet: {
                thumbnails: {
                  medium: {url: item.snippet.thumbnails.medium.url},
                },
              },
            });
            return acc;
          }, [] as any);
          // if
          return channelNeededInfo;
        });
        console.log('combinedInfo', combinedInfo);
        const finalArray = [...combinedInfo[0], ...combinedInfo[1]];
        setChannelsArray(finalArray);
        const itemsToStore: StoredKeywordItemValue = {
          videosIdsArray: videoChannelsId,
          videosInfoArray: [],
          channelsInfoArray: finalArray,
        };
        setLocallyStoredData(
          `mostViewedVideos_${timePeriodFromStore}`,
          itemsToStore
        );

        recountVideosList(topVideosData, itemsToStore?.channelsInfoArray);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setChannelsLoading(false);
      });
  };

  return (
    <Box ml={4} my={5} pb={6}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize={'24px'} fontWeight={700}>
          Most viewed videos
        </Text>
        <Box className={style.pill}>
          <Pills
            size="m"
            value={pillValue}
            onChange={setPillValue}
            className={style.pillTab}
          >
            <Pills.Item value={0} tabIndex={0} neighborLocation="right">
              <Pills.Item.Text>Last Day</Pills.Item.Text>
            </Pills.Item>
            <Pills.Item value={1} tabIndex={1} neighborLocation="both">
              Last Week
            </Pills.Item>
            <Pills.Item value={2} tabIndex={2} neighborLocation="left">
              Last Month
            </Pills.Item>
          </Pills>
        </Box>
      </Flex>
      <Flex justifyContent="center" w="100%">
        <Box w={'95%'}>
          {/* @ts-ignore */}
          <Flex flexWrap="true" gap={3} mt={3} justifyContent="center">
            {loading || channelsLoading ? (
              <Box
                mt={4}
                h={'100%'}
                w={'100%'}
                justifyContent="center"
                alignItems="center"
              >
                <Spin centered />
              </Box>
            ) : (
              videosList
                .slice((currentPage - 1) * perPage, currentPage * perPage)
                .map((video, index) => (
                  <TopVideoCard
                    key={`video-key-${index}`}
                    index={index}
                    video={video.video}
                    channelInfo={video.ownerChannel}
                    timePeriod={
                      pillValue === 0
                        ? TimePeriod.day
                        : pillValue === 1
                        ? TimePeriod.week
                        : TimePeriod.month
                    }
                  />
                ))
            )}
          </Flex>
          <Flex justifyContent="end" alignItems="center" mt={3}>
            <Pagination
              currentPage={currentPage}
              onCurrentPageChange={updateCurrentPage}
              totalPages={Math.ceil(videosList.length / perPage)}
            >
              <Pagination.FirstPage />
              <Pagination.PrevPage />
              <Pagination.NextPage />
              <Pagination.PageInput />
              <Pagination.TotalPages mr={4} />
              <Select
                value={perPage}
                onChange={changePerPageAmount}
                options={[60, 30, 12, 6, 3].map((value) => ({
                  value,
                  children: value,
                }))}
              />
            </Pagination>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
});
