import Button from '@semcore/ui/button';
import DataTable, {DataTableData, DataTableSort} from '@semcore/ui/data-table';
import {Box, Flex} from '@semcore/ui/flex-box';
import Input from '@semcore/ui/input';
import Pagination from '@semcore/ui/pagination';
import Pills from '@semcore/ui/pills';
import Select from '@semcore/ui/select';
import {scaleLinear} from 'd3-scale';
import {ArrowDown, ArrowUp} from '../../../assets/img';
import SpinContainer from '@semcore/spin-container';
import Breakpoints from '@semcore/ui/breakpoints';

import {
  Area,
  HoverLine,
  minMax,
  Plot,
  Tooltip as D3Tooltip,
} from '@semcore/ui/d3-chart';

import style from './style.module.scss';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  FastGrowingKeywordListItem,
  getFastGrowingKeywords,
  getFastGrowingKeywordsList,
  GetFastGrowingKeywordsParams,
  GetSearchFastGrowingKeywordListParams,
  TimePeriod,
} from '../../../api/request';
import store from '../../../stores/store';
import {NoData} from '@semcore/ui/widget-empty';
import {
  convertTimePeriodToPillValue,
  recountSearchVolume,
  recountTooltipValues,
} from '../../../utils/helpers';
import {observer} from 'mobx-react';
import {curveCardinal} from 'd3-shape';
import Ellipsis from '@semcore/ui/ellipsis';
import Tooltip from '@semcore/ui/tooltip';
import {DifferenceTooltip} from '../../../components/DifferenceTooltip/DifferenceTooltip';
import {SearchInput} from '../../../components/SearchInput/SearchInput';

const MARGIN = 5;
const width = 150;
const heightChart = 35;

interface Props {
  amount: number;
}

export const FastGrowingKeywordsDataTableSection: React.FC<Props> = observer(
  (props) => {
    const [keyword, setKeyword] = useState<string>('');

    const [pillValue, setPillValue] = useState<number>(
      convertTimePeriodToPillValue(store.chosenFastGrowingAggregationDate)
    );
    const [previousPillValue, setPreviousPillValue] = useState<number>(
      convertTimePeriodToPillValue(store.chosenFastGrowingAggregationDate)
    );

    const [tableSort, setTableSort] = useState<DataTableSort & string[]>([
      'last_diff',
      'desc',
    ]);
    const [tableActiveRow, setTableActiveRow] =
      useState<FastGrowingKeywordListItem | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);

    // const [keywordsList, setKeywordsList] = useState<F[]>([]);
    const [tableData, setTableData] = useState<FastGrowingKeywordListItem[]>(
      []
    );

    const countryFromStore = JSON.stringify(store.chosenCountry);

    // const [perPageAmount, setPerPageAmount] = useState<number>(10);

    const manualSearchFlag = useRef<boolean>(false);

    // const perPage = useRef<number>(10);

    // const [innerHeight, setInnerHeight] = useState<number>(1200);

    // useEffect(() => {
    //   // window.addEventListener('resize', recountAmountOfRowsInTable);

    //   const resizeObserver = new ResizeObserver((el) => {
    //     recountAmountOfRowsInTable();
    //   });
    //   const heightDiv = document.querySelector('#heightControl');
    //   if (!heightDiv) return;

    //   resizeObserver.observe(heightDiv);

    //   return () => {
    //     resizeObserver.unobserve(heightDiv);
    //   };
    //   // window.removeEventListener('resize', recountAmountOfRowsInTable);
    // }, []);

    // const recountAmountOfRowsInTable = () => {
    //   const beforeTable = 145;
    //   const afterTable = 84;

    //   const amount = Math.floor((window.innerHeight - 145 - 84) / 60);

    //   console.log('ava', amount);

    //   if (amount !== perPage.current) {
    //     // perPage.current = amount > 10 ? 10 : amount;
    //     perPage.current = amount;
    //     setPerPageAmount(perPage.current);
    //   }
    // };

    // useEffect(() => {
    //   perPage.current = props.amount;
    //   setPerPageAmount(props.amount);
    // }, [props.amount]);

    useEffect(() => {
      handleSearch();
    }, [props.amount]);

    useEffect(() => {
      // handleKeywordSearch('xbox');
      handleSearch();
    }, [pillValue, tableSort[0], tableSort[1], countryFromStore]);

    useEffect(() => {
      if (manualSearchFlag.current) return;
      handleSearch();
    }, [currentPage]);

    const handleSearch = () => {
      console.log('pillValue', pillValue);
      const timePeriod =
        pillValue === 0
          ? TimePeriod.day
          : pillValue === 1
          ? TimePeriod.week
          : TimePeriod.month;
      console.log('pillValue timePeriod', timePeriod);
      // if (timePeriod !== store.chosenAggregationDate) {
      store.setChosenAggregationDate(timePeriod, 'fastGrowing');
      // }

      if (manualSearchFlag.current) {
        console.log('was in specific keyword');
        handleKeywordSearch();
      } else {
        console.log('was in overall keyword');
        handleFastGrowingKeywordsSearch();
      }
    };

    const enterKeyword = (keyword: string) => {
      setKeyword(keyword);
    };

    const handleFastGrowingKeywordsSearch = () => {
      const timePeriod =
        pillValue === 0
          ? TimePeriod.day
          : pillValue === 1
          ? TimePeriod.week
          : TimePeriod.month;

      const fastGrowingKeywordsParams: GetFastGrowingKeywordsParams = {
        period: timePeriod,
        // sort_order: tableSort?.[1],
        // per_page: perPage.current,
        per_page: props.amount,
        page: currentPage,
        country: store.chosenCountry,
        order_by: tableSort.join(' '),
      };
      setLoading(true);
      getFastGrowingKeywords(fastGrowingKeywordsParams)
        .then((json) => {
          console.log('getFastGrowingKeyword', json);
          setTableData(json.keyword_data);
          store.setCurrentActiveFastGrowingKeyword(json.keyword_data[0]);
          store.findMaximumSearchVolumeForFastGrowingKeywords(
            json.keyword_data
          );

          setTotalPages(Math.ceil(json.meta.total / props.amount));

          if (previousPillValue !== pillValue) {
            setPreviousPillValue(pillValue);

            recountSearchVolume(json.keyword_data, 'fastGrowingKeywords');
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const handleKeywordSearch = (specificKeyword?: string) => {
      if (!specificKeyword && !keyword) {
        handleFastGrowingKeywordsSearch();
        return;
      }
      const timePeriod =
        pillValue === 0
          ? TimePeriod.day
          : pillValue === 1
          ? TimePeriod.week
          : TimePeriod.month;
      const keywordToSearch = specificKeyword || keyword;
      console.log('keywordToSearch', keywordToSearch);
      setLoading(true);
      const searchObj: GetSearchFastGrowingKeywordListParams = {
        keyword: keywordToSearch,
        country: store.chosenCountry,
        period: timePeriod,
        device: 'all',
        order_by: tableSort.join(' '),
      };
      console.log('_-search-_', searchObj);
      getFastGrowingKeywordsList(searchObj)
        .then((json) => {
          console.log('json in getKeywordsListSuccess', json);
          const jsonData = json.reduce((acc, keyword) => {
            //@ts-ignore
            if (keyword?.keyword) {
              //@ts-ignore
              acc.push({...{keyword_tar: keyword?.keyword}, ...keyword});
            } else {
              acc.push(keyword);
            }
            return acc;
          }, [] as any);
          setTableData(jsonData);
          store.setCurrentActiveFastGrowingKeyword(jsonData[0]);
          store.findMaximumSearchVolumeForFastGrowingKeywords(jsonData);

          setTotalPages(Math.ceil(json.length / props.amount));
        })
        .catch((err) => {
          console.log(err);
          setTableData([]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const changePage = (pageNumber: number) => {
      setCurrentPage(pageNumber);
    };

    const handleKeyboardPress = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === 'Enter') {
        setLoading(true);
        setTimeout(() => {
          if (keyword?.length > 0) {
            manualSearchFlag.current = true;
          } else {
            manualSearchFlag.current = false;
          }
          setCurrentPage(1);
          handleSearch();
        });
      }
    };

    const renderGraph = (
      currentKeyword: FastGrowingKeywordListItem,
      height = heightChart
    ) => {
      let formattedData =
        currentKeyword?.diff_array
          .reduce((acc, item, index) => {
            acc.push({x: index, y: item + 2});
            return acc;
          }, [] as any)
          .reverse() || [];

      type point = {x: number; y: number};

      const filteredArray = formattedData?.filter(
        (item: point) => item.y !== null || item.y !== undefined
      );

      if (formattedData?.length < 2) {
        let additionalArray = Array.from({
          length: 30 - formattedData?.length,
        }).reduce((acc, item, index) => {
          //@ts-ignore
          acc.push({x: index, y: null});
          return acc;
        }, [] as any) as any[];

        formattedData = [...additionalArray, ...formattedData].reduce(
          (acc, item, index) => {
            acc.push({x: index, y: item?.y || null});
            return acc;
          },
          [] as any
        );
      }

      if (formattedData?.length < 7) {
        let additionalArray = Array.from({
          length: 7 - formattedData?.length,
        }).reduce((acc, item, index) => {
          //@ts-ignore
          acc.push({
            x: index,
            y: formattedData?.[formattedData?.length - 1]?.y,
          });
          return acc;
        }, [] as any) as any[];

        formattedData = [...formattedData, ...additionalArray].reduce(
          (acc, item, index) => {
            acc.push({x: index, y: item?.y || null});
            return acc;
          },
          [] as any
        );
      }

      const xScale = scaleLinear()
        .range([8, filteredArray.length <= 1 ? width - 20 : width - 4])
        .domain([2, formattedData?.length]);
      const yScale = scaleLinear()
        .range([height - MARGIN, MARGIN])
        .domain(minMax(formattedData, 'y'));

      return (
        <Plot
          height={height}
          width={width}
          data={formattedData}
          scale={[xScale, yScale]}
        >
          <D3Tooltip tag={HoverLine} x="x" wMin={250}>
            {(props: any) => {
              console.log('keyword', currentKeyword);
              const {
                currentMoment,
                sinceMoment,
                currentValues,
                pastValues,
                differencePercent,
                differenceValue,
              } = recountTooltipValues(currentKeyword, pillValue);
              console.log('keyword', sinceMoment);

              return {
                children: (
                  <DifferenceTooltip
                    currentMoment={currentMoment}
                    sinceMoment={sinceMoment}
                    currentValues={currentValues}
                    pastValues={pastValues}
                    differencePercent={differencePercent}
                    differenceValue={differenceValue}
                  />
                ),
              } as any;
            }}
          </D3Tooltip>
          <Area x="x" y="y" curve={curveCardinal}>
            <Area.Null></Area.Null>
            <Area.Line style={{strokeWidth: 1}}></Area.Line>
            {filteredArray?.length <= 1 && <Area.Dots></Area.Dots>}
          </Area>
        </Plot>
      );
    };

    const currentTableData = manualSearchFlag.current
      ? tableData?.slice(
          (currentPage - 1) * props.amount,
          props.amount * currentPage
        )
      : tableData;

    const handleSearchButtonPress = () => {
      setLoading(true);
      setTimeout(() => {
        if (keyword.length > 0) {
          manualSearchFlag.current = true;
        } else {
          manualSearchFlag.current = false;
        }
        setCurrentPage(1);
        handleKeywordSearch();
      });
    };

    return (
      <Box
        w="50%"
        pr={5}
        pt={5}
        style={{borderRight: '1px solid #C4C7CF'}}
        // h={'100%'}
        pb={6}
      >
        <Flex justifyContent="space-between">
          <SearchInput
            handleKeyboardPress={handleKeyboardPress}
            handleSearchButtonPress={handleSearchButtonPress}
            handleSearchInputAction={enterKeyword}
            currentSearchValue={keyword}
          />

          <Pills
            size="m"
            value={pillValue}
            onChange={setPillValue}
            className={style.pillTab}
          >
            <Pills.Item value={0} neighborLocation="right">
              <Pills.Item.Text>Last Day</Pills.Item.Text>
            </Pills.Item>
            <Pills.Item value={1} neighborLocation="both">
              <Pills.Item.Text>Last Week</Pills.Item.Text>
            </Pills.Item>
            <Pills.Item value={2} neighborLocation="left">
              <Pills.Item.Text>Last Month</Pills.Item.Text>
            </Pills.Item>
          </Pills>
        </Flex>

        <Box
          style={{background: 'white', borderRadius: 6}}
          mt={3}
          className="shadow-border"
        >
          <Flex direction="column" justifyContent="space-between">
            <DataTable
              data={currentTableData}
              // sort={tableSort}
              // onSortChange={setTableSort}
              use="primary"
            >
              <DataTable.Head
                style={{backgroundColor: 'white', cursor: 'default'}}
                use="secondary"
              >
                <DataTable.Column
                  name="keyword_tar"
                  children="Keyword Name"
                  flex={5}
                  // sortable
                />
                <DataTable.Column
                  name="diff_array"
                  children="Trend"
                  flex={3}
                  // sortable
                />
                <DataTable.Column
                  name="last_diff"
                  children="Change"
                  flex={1}
                  justifyContent="end"
                  sortable
                />
                <DataTable.Column
                  flex={1}
                  justifyContent="start"
                  name="counts"
                  children="Search Volume"
                  // sortable
                />
              </DataTable.Head>
              {!loading && (!tableData || tableData.length === 0) && (
                <NoData h={400} />
              )}
              <DataTable.Body
                style={loading ? {minHeight: 400} : {}}
                tag={SpinContainer}
                loading={loading}
              >
                <DataTable.Row>
                  {(props, row, index) => {
                    return {
                      style: {
                        outerHeight: 44,
                        cursor: 'pointer',
                      },
                      onClick: () => {
                        store.setCurrentActiveFastGrowingKeyword(
                          row as FastGrowingKeywordListItem
                        );
                        setTableActiveRow(row as FastGrowingKeywordListItem);
                      },
                      active:
                        store.currentActiveFastGrowingKeyword?.keyword_tar ===
                        row.keyword_tar,
                      children: props.children,
                    };
                  }}
                </DataTable.Row>
                <DataTable.Cell name="keyword_tar">
                  {(props: any, row: DataTableData, index: number) => {
                    return {
                      style: {
                        alignItems: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                      children: (
                        <Box w={'170px'}>
                          <Ellipsis
                            maxLine={1}
                            tooltip={
                              (props.children.props.children + '').length > 25
                            }
                            trim="end"
                            // containerRect={{width: 160}}
                          >
                            {props.children.props.children}
                          </Ellipsis>
                        </Box>
                      ),
                    };
                  }}
                </DataTable.Cell>
                <DataTable.Cell name="keyword_tar/last_diff/counts">
                  {(props: any, row: DataTableData, index: number) => {
                    return {
                      style: {
                        alignItems: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                      // children: props.children
                    };
                  }}
                </DataTable.Cell>
                <DataTable.Cell name="diff_array">
                  {(propsInd: any, row: DataTableData, index: number) => {
                    const correctIndex = manualSearchFlag.current
                      ? (currentPage - 1) * props.amount + index || 0
                      : index;
                    return {
                      style: {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },

                      children: renderGraph(tableData?.[correctIndex]),
                    };
                  }}
                </DataTable.Cell>
                <DataTable.Cell name="last_diff">
                  {(props: any, row: any, index: number) => {
                    const value = props.children?.props?.children;
                    return {
                      children: (
                        <Box
                          className={
                            value > 0
                              ? style.positiveChange
                              : value < 0
                              ? style.negativeChange
                              : {}
                          }
                        >
                          <Tooltip>
                            <Tooltip.Trigger>
                              <Flex alignItems="center">
                                {value > 0 ? (
                                  <ArrowUp fill="green" height={14} />
                                ) : value < 0 ? (
                                  <ArrowDown fill="red" height={14} />
                                ) : null}
                                <span
                                  style={{
                                    //   marginLeft: 3,
                                    fontSize: 14,
                                    lineHeight: '20px',
                                  }}
                                >
                                  {Intl.NumberFormat('en', {
                                    compactDisplay: 'short',
                                    notation: 'compact',
                                  })?.format(
                                    props.children.props.children || 0
                                  )}
                                </span>
                              </Flex>
                            </Tooltip.Trigger>
                            <Tooltip.Popper>
                              {
                                'Change of keyword performance during selected time period'
                              }
                            </Tooltip.Popper>
                          </Tooltip>
                        </Box>
                      ),
                    };
                  }}
                </DataTable.Cell>
                <DataTable.Cell name="counts">
                  {(props: any, row: DataTableData, index: number) => {
                    console.log(props);

                    return {
                      children: (
                        <div style={{paddingRight: 20}}>
                          {Intl.NumberFormat('en', {
                            compactDisplay: 'short',
                            notation: 'compact',
                          })?.format(props.children.props.children || 0)}
                        </div>
                      ),
                    };
                  }}
                </DataTable.Cell>
              </DataTable.Body>
            </DataTable>

            <Pagination
              className={style.pagination__input__withPadding}
              style={{justifyContent: 'flex-end'}}
              mt={4}
              mr={4}
              mb={4}
              currentPage={currentPage}
              onCurrentPageChange={changePage}
              totalPages={totalPages || 1}
            />
          </Flex>
        </Box>
      </Box>
    );
  }
);

// if (formattedData.length < 6) {
//   let additionalArray = Array.from({
//     length: 6 - formattedData.length,
//   }).reduce((acc, item, index) => {
//     //@ts-ignore
//     acc.push({x: index, y: formattedData?.[0]?.y});
//     return acc;
//   }, [] as any) as any[];

//   formattedData = [
//     ...additionalArray,
//     ...formattedData,
//     ...additionalArray,
//   ].reduce((acc, item, index) => {
//     acc.push({x: index, y: item?.y || null});
//     return acc;
//   }, [] as any);
// }

// let additionalArray = Array.from({
//   length: 30 - formattedData.length,
// }).reduce((acc, item, index) => {
//   //@ts-ignore
//   acc.push({x: formattedData.length + index, y: null});
//   return acc;
// }, [] as any) as any[];

// let additionalArray = Array.from({
//   length: 30 - formattedData.length,
// }).reduce((acc, item, index) => {
//   //@ts-ignore
//   acc.push({x: index, y: null});
//   return acc;
// }, [] as any) as any[];

// console.log(
//   'formattedData before',
//   JSON.parse(JSON.stringify(formattedData))
// );

// // formattedData = formattedData.concat(additionalArray);
// formattedData = [...additionalArray, ...formattedData].reduce(
//   (acc, item, index) => {
//     acc.push({x: index, y: item?.y || null});
//     return acc;
//   },
//   [] as any
// );
// console.log('formattedData', JSON.parse(JSON.stringify(formattedData)));
// formattedData.reverse();
// console.log('reversed formattedData', formattedData);
