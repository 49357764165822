import qs from 'qs';
import {ExportToCsv} from 'export-to-csv';
import moment from 'moment';
import {
  FastGrowingKeywordListItem,
  KeywordListItem,
  TimePeriod,
} from '../api/request';
import {
  StoredKeywordItem,
  StoredKeywordItemValue,
  StoredTopVideoItemValue,
} from '../types/entity';
import store from '../stores/store';

export enum StatusColors {
  green = '#27AE60',
  yellow = '#F2C94C',
  orange = '#F2994A',
  red = '#EB5757',
}

//парсит строку гет параметров в объект
export const getQuery = (query: string) => {
  return qs.parse(query, {ignoreQueryPrefix: true});
};

//собирает строку из объекта параметров
export const setQuery = (params: {[key: string]: any}, noPrefix?: boolean) => {
  return qs.stringify(params, {addQueryPrefix: !noPrefix});
};

export const EventEmitter = {
  events: {} as any,
  execute: function (event: string, data: any) {
    if (!this.events[event]) return;
    this.events[event].forEach((callback: any) => callback(data));
  },
  subscribe: function (event: string, callback: any) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  delete: function (event: string) {
    delete this.events[event];
  },
};

export const csvExporter = (options: any, data: any) => {
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(data);
};

export const roundThousand = (value: any) => {
  if (value >= 1000000) {
    return gaussRound(+value / 1000000, 1) + 'M';
  } else if (value >= 1000) {
    return gaussRound(+value / 1000, 1) + 'K';
  } else {
    return value;
  }
};

//округление по гаусу
export const gaussRound = (num: number, decimalPlaces: number) => {
  let d = decimalPlaces || 0,
    m = Math.pow(10, d),
    n = +(d ? num * m : num).toFixed(8),
    i = Math.floor(n),
    f = n - i,
    e = 1e-8,
    r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};
/**
 * get cached keyword data (for youtube API)
 * @param key - keyword to get
 * @returns keyword data for videos or null, if item is expired or not found
 */
export const getLocallyStoredData = (key: string | 'mostViewedVideos') => {
  try {
    const item: StoredKeywordItem = JSON.parse(
      localStorage?.getItem(key + '_' + store.chosenCountry) || 'null'
    );
    const expiryTime = item?.expiry;
    const currentTime = moment().unix();
    if (!item || currentTime >= expiryTime) {
      localStorage?.removeItem(key);
      return null;
    }
    const isKeyword = key === 'mostViewedVideos';
    if (isKeyword) {
      return item.value as StoredKeywordItemValue;
    } else {
      return item?.value as StoredTopVideoItemValue;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};
/**
 * cache response for youtube API
 * @param key - keyword's name
 * @param value - data to store
 */
export const setLocallyStoredData = (
  key: string,
  value: StoredKeywordItemValue
) => {
  const currentStorageSize = Number(getLocalStorageSize() || 0);
  if (currentStorageSize >= 4800) {
    console.log('will clear localStore', currentStorageSize);
    try {
      localStorage?.clear();
    } catch (err) {
      console.log(err);
    }
  }
  const currentTime = moment().unix();
  // cache item for a day
  const keywordValue: StoredKeywordItem = {
    expiry: currentTime + 86400,
    value: value,
  };
  try {
    localStorage?.setItem(
      key + '_' + store.chosenCountry,
      JSON.stringify(keywordValue)
    );
  } catch (err) {
    console.log(err);
  }
};

export const convertNumberToShortString = (number: number) => {
  const intl = Intl.NumberFormat('en', {
    compactDisplay: 'short',
    notation: 'compact',
  })?.format(number);

  let toDisplay = intl;

  switch (intl[intl.length - 1]) {
    case 'K':
      break;
    case 'M':
      toDisplay = intl.replace('M', ' mln');
      break;
    case 'B':
      toDisplay = intl.replace('B', ' bln');
      break;
  }

  return toDisplay;
};

export const recountSearchVolume = (
  countArray: KeywordListItem[] | FastGrowingKeywordListItem[],
  tab: 'topKeywords' | 'fastGrowingKeywords'
) => {
  if (tab === 'topKeywords') {
    store.maxSearchVolume = 0;
    store.findMaximumSearchVolume(countArray as KeywordListItem[]);
  } else {
    store.maxFastGrowingKeywordsSearchVolume = 0;
    store.findMaximumSearchVolumeForFastGrowingKeywords(
      countArray as FastGrowingKeywordListItem[]
    );
  }
};

export const getCompetitiveRateColor = (rate: number) => {
  if (rate <= 25) {
    return StatusColors.green;
  } else if (rate <= 50) {
    return StatusColors.yellow;
  } else if (rate <= 75) {
    return StatusColors.orange;
  } else {
    return StatusColors.red;
  }
};

export const getCompetitiveRateText = (rate: number) => {
  if (rate <= 25) {
    return 'Low';
  } else if (rate <= 50) {
    return 'Medium';
  } else if (rate <= 75) {
    return 'Medium';
  } else {
    return 'High';
  }
};

export const getCompetitiveSearchColor = (
  rate: number,
  type: 'top' | 'fastGrowing'
) => {
  const maxValue =
    type === 'top'
      ? store.maxSearchVolume
      : store.maxFastGrowingKeywordsSearchVolume;

  const difference = rate / maxValue;

  if (difference <= 0.25) {
    return StatusColors.green;
  } else if (difference <= 0.5) {
    return StatusColors.yellow;
  } else if (difference <= 0.75) {
    return StatusColors.orange;
  } else {
    return StatusColors.red;
  }
};

export const getCompetitiveSearchText = (
  rate: number,
  type: 'top' | 'fastGrowing'
) => {
  const maxValue =
    type === 'top'
      ? store.maxSearchVolume
      : store.maxFastGrowingKeywordsSearchVolume;

  const difference = rate / maxValue;

  if (difference <= 0.25) {
    return 'Low';
  } else if (difference <= 0.5) {
    return 'Medium';
  } else if (difference <= 0.75) {
    return 'Medium';
  } else {
    return 'High';
  }
};
/**
 * tooltip description by tooltip title
 * @param text either Low, Medium or High
 * @param type top or fastGrowing (tabs)
 * @returns
 */
export const getTooltipTextByCompetitiveText = (
  text: 'Low' | 'Medium' | 'High',
  type: 'top' | 'fastGrowing'
) => {
  switch (text) {
    case 'Low':
      return type === 'top'
        ? 'Low competition in Youtube search. Great option for channels with less than 1M subscribers.'
        : 'Users rarely search for this keyword in Youtube';
    case 'Medium':
      return type === 'top'
        ? 'Average level of competition in Youtube search. Good for channels with up to 1M subscribers or more.'
        : 'Average number of searches for this keyword in Youtube search';
    case 'High':
      return type === 'top'
        ? 'High level of competition in Youtube search. Suitable for channels having 1M and more subscribers.'
        : 'Users often search for this keyword in Youtube searches';
  }
};
/**
 * return current size of items in localStorage (for caching stuff)
 * @returns
 */
export const getLocalStorageSize = () => {
  let _lsTotal = 0,
    _xLen,
    _x;
  try {
    for (_x in localStorage) {
      if (!localStorage?.hasOwnProperty(_x)) {
        continue;
      }
      _xLen = (localStorage?.[_x]?.length + _x.length) * 2;
      _lsTotal += _xLen;
    }
  } catch (err) {
    console.log(err);
  }
  return (_lsTotal / 1024).toFixed(2);
};

export const convertTimePeriodToPillValue = (timePeriod: TimePeriod) => {
  return timePeriod === TimePeriod.day
    ? 0
    : timePeriod === TimePeriod.week
    ? 1
    : 2;
};

/**
 * recounting values for graph's flying tooltips
 * @param currentKeyword - current keyword
 * @param pillValue - Time Period (0 - day, 1 - week, 2 - month)
 * @returns - object for DifferenceTooltip component
 */
export const recountTooltipValues = (
  currentKeyword: FastGrowingKeywordListItem,
  pillValue: number
) => {
  const momentFormat = pillValue === 2 ? 'MMM YYYY' : 'D MMM YYYY';

  let currentMoment = moment({}).format(momentFormat);
  let sinceMoment = moment({})
    .subtract(1, store.chosenFastGrowingAggregationDate || 'month')
    .format(momentFormat);

  console.log('sinceMoment in function', sinceMoment);
  console.log('sinceMoment manually', store.chosenFastGrowingAggregationDate);

  let firstDateOfCurrentMoment = '';
  let secondDateOfCurrentMoment = '';

  let firstDateOfSinceMoment = '';
  let secondDateOfSinceMoment = '';

  if (pillValue === 1) {
    let time = moment({});
    secondDateOfCurrentMoment = time.format('D MMM YYYY'); // always should have month and year
    firstDateOfCurrentMoment = time.subtract(1, 'weeks').format('D MMM YYYY'); // only what differs from the second date

    let sinceTime = moment({}).subtract(1, 'week');
    secondDateOfSinceMoment = sinceTime.format('D MMM YYYY');
    firstDateOfSinceMoment = sinceTime
      .subtract(1, 'weeks')
      .format('D MMM YYYY');

    /////
    let firstDateOfCurrentMomentArray = firstDateOfCurrentMoment.split(' '); // ['23', 'Jan', '2023'];
    let secondDateOfCurrentMomentArray = secondDateOfCurrentMoment.split(' ');

    let finalFirstDateOfCurrentMoment = '';

    let firstDateOfSinceMomentArray = firstDateOfSinceMoment.split(' ');
    let secondDateOfSinceMomentArray = secondDateOfCurrentMoment.split(' ');

    let finalFirstDateOfSinceMoment = '';

    for (let i = 0; i < 3; i++) {
      if (
        secondDateOfCurrentMomentArray[i] !== firstDateOfCurrentMomentArray[i]
      ) {
        finalFirstDateOfCurrentMoment += firstDateOfCurrentMomentArray[i] + ' ';
      }

      if (secondDateOfSinceMomentArray[i] !== firstDateOfSinceMomentArray[i]) {
        finalFirstDateOfSinceMoment += firstDateOfSinceMomentArray[i] + ' ';
      }
    }
    //////

    sinceMoment = `${finalFirstDateOfSinceMoment} - ${secondDateOfSinceMoment}`;
    currentMoment = `${finalFirstDateOfCurrentMoment} - ${secondDateOfCurrentMoment}`;
  }

  // const currentValues = currentKeyword.diff_array.reduce(
  //   (acc, value) => (acc += value),
  //   0
  // );
  const currentValues = currentKeyword.counts;

  const differenceValue = currentKeyword.last_diff;
  const pastValues = currentValues - differenceValue;

  let differencePercent = String((differenceValue / currentValues) * 100);

  if (differencePercent.includes('.')) {
    differencePercent =
      Math.round((+differencePercent + Number.EPSILON) * 100) / 100 + '';
  }

  return {
    currentMoment,
    sinceMoment,
    currentValues,
    pastValues,
    differencePercent,
    differenceValue,
  };
};

export const throttle = (func: () => void, ms: number) => {
  let isThrottled = false;
  let savedArgs: any;
  let savedThis: any;

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments;
      //@ts-ignore
      savedThis = this;
      return;
    }
    //@ts-ignore
    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
};
