import Button from '@semcore/ui/button';
import DataTable, {DataTableData, DataTableSort} from '@semcore/ui/data-table';
import {Box, Flex} from '@semcore/ui/flex-box';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {InfoCardItem} from '../../../components/InfoCardItem/InfoCardItem';
import {VideoSwiperSection} from '../../../components/VideoSwiperSection/VideoSwiperSection';
import {scaleLinear} from 'd3-scale';
import style from './style.module.scss';
import {
  Area,
  HoverLine,
  minMax,
  Plot,
  Tooltip as D3Tooltip,
  ResponsiveContainer,
} from '@semcore/ui/d3-chart';
import {Text} from '@semcore/ui/typography';
import {Row, Col} from '@semcore/grid';
import FileExportXS from '@semcore/icon/lib/FileExport/xs';
import {ArrowDown, ArrowUp} from '../../../assets/img';
import store from '../../../stores/store';
import {observer} from 'mobx-react';
import {
  FastGrowingKeywordListItem,
  getChannelInfo,
  getFastGrowingKeywordsList,
  GetSearchFastGrowingKeywordListParams,
  getTopVideosIdByKeyword,
  getYoutubeVideoInfo,
  TimePeriod,
  YoutubeChannelItem,
  YoutubeVideoInfoItem,
} from '../../../api/request';

import SpinContainer from '@semcore/spin-container';
import {
  convertNumberToShortString,
  getCompetitiveRateColor,
  getCompetitiveRateText,
  getCompetitiveSearchColor,
  getCompetitiveSearchText,
  getLocallyStoredData,
  getTooltipTextByCompetitiveText,
  recountTooltipValues,
  setLocallyStoredData,
} from '../../../utils/helpers';
import {StoredKeywordItemValue} from '../../../types/entity';
import WidgetEmpty, {getIconPath, NoData} from '@semcore/ui/widget-empty';
import {curveCardinal} from 'd3-shape';
import moment from 'moment';
import Link from '@semcore/ui/link';

import Ellipsis from '@semcore/ui/ellipsis';
import Tooltip from '@semcore/ui/tooltip';
import {DifferenceTooltip} from '../../../components/DifferenceTooltip/DifferenceTooltip';

const width = 150;
const heightChart = 35;

interface Props {
  amount: number;
}

export const FastGrowingKeywordsDetailedInfo: React.FC<Props> = observer(
  (props) => {
    const [pdfLoading, setPdfLoading] = useState<boolean>(false);
    const [tableSort, setTableSort] = useState<DataTableSort & string[]>([
      'last_diff',
      'desc',
    ]);
    const [loading, setLoading] = useState<boolean>(true);

    const [keywordDetailedData, setKeywordDetailedData] = useState<
      FastGrowingKeywordListItem[] | null
    >([]);

    const [videoData, setVideoData] = useState<YoutubeVideoInfoItem[]>([]);
    const [videosDataLoading, setVideosDataLoading] = useState<boolean>(true);
    const [channelsData, setChannelsData] = useState<YoutubeChannelItem[]>([]);

    const [showTableBlock, setShowTableBlock] = useState<boolean>(false);

    const activeKeywordInStore =
      store.currentActiveFastGrowingKeyword?.keyword_tar;

    const timePeriod = store.chosenFastGrowingAggregationDate;

    useEffect(() => {
      if (!store.currentActiveFastGrowingKeyword?.keyword_tar) {
        return;
      }
      setLoading(true);

      const searchObj: GetSearchFastGrowingKeywordListParams = {
        keyword: store.currentActiveFastGrowingKeyword?.keyword_tar ?? '',
        period: store.chosenFastGrowingAggregationDate,
        country: store.chosenCountry,
        device: 'all',
        order_by: tableSort.join(' '),
      };
      getFastGrowingKeywordsList(searchObj)
        .then((json) => {
          setKeywordDetailedData(json);
        })
        .catch((err) => {
          setKeywordDetailedData(null);
        })
        .finally(() => setLoading(false));

      if (store.currentActiveFastGrowingKeyword) {
        const storedKeywordData = getLocallyStoredData(
          store.currentActiveFastGrowingKeyword?.keyword_tar +
            '_fast_growing' +
            `_${timePeriod}`
        ) as StoredKeywordItemValue | null;
        if (storedKeywordData) {
          setVideoData(storedKeywordData.videosInfoArray);
          setChannelsData(storedKeywordData.channelsInfoArray);
          setVideosDataLoading(false);
          return;
        }
        if (
          store.currentActiveFastGrowingKeyword?.video_ref &&
          store.currentActiveFastGrowingKeyword.video_ref.length > 0
        ) {
          const videoIdArray =
            store.currentActiveFastGrowingKeyword.video_ref.map((item) => {
              const id = item.split(',')?.[0];
              return id;
            });

          getVideoInfo(videoIdArray);
        } else {
          getTopVideosIdByKeyword(
            store.currentActiveFastGrowingKeyword.keyword_tar
          ).then((json) => {
            console.log('getTopVideosIdByKeywordSuccess', json.items.length);
            if (json.items?.length > 0) {
              const videoIdList = json.items.map((video) => video.id.videoId);

              getVideoInfo(videoIdList);
            }
          });
        }
      }
    }, [activeKeywordInStore, tableSort[0], tableSort[1], timePeriod]);

    // const getVideoInfo = (json: TopVideosByKeywordResponse) => {
    const getVideoInfo = (videosIdArray: string[]) => {
      setVideosDataLoading(true);
      // const videosIdArray = json.items.map((video) => video.id.videoId);
      console.log('about to get videos info');

      getYoutubeVideoInfo(videosIdArray)
        .then((videoJSON) => {
          const videoDataArray = videoJSON.items.sort((a, b) => {
            const currentIdFromServer =
              store.currentActiveKeyword?.video_ref?.find(
                (storeVideo) => storeVideo?.split(',')?.[0] === a.id
              );
            const nextIdFromServer =
              store.currentActiveKeyword?.video_ref?.find(
                (storeVideo) => storeVideo?.split(',')?.[0] === b.id
              );

            const currentIdVolume =
              Number(currentIdFromServer?.split(',')?.[1]) || 0;
            const nextIdVolume = Number(nextIdFromServer?.split(',')?.[1]) || 0;

            return nextIdVolume - currentIdVolume;
          });
          setVideoData(videoDataArray);

          const channelsIdArray = videoJSON.items.map(
            (video) => video.snippet.channelId
          );

          getChannelInfo(channelsIdArray)
            .then((channelJSON) => {
              setChannelsData(channelJSON.items);

              const channelNeededInfo =
                channelJSON?.items?.reduce((acc, item, index) => {
                  acc.push({
                    id: item.id,
                    statistics: {
                      subscriberCount: item.statistics.subscriberCount,
                    },
                    snippet: {
                      thumbnails: {
                        medium: {url: item.snippet.thumbnails.medium.url},
                      },
                    },
                  });
                  return acc;
                }, [] as any) || [];

              const videosNeededInfo =
                videoJSON?.items?.reduce((acc, video, index) => {
                  acc.push({
                    id: video.id,
                    snippet: {
                      channelId: video?.snippet?.channelId,
                      publishedAt: video?.snippet?.publishedAt,
                      title: video?.snippet?.title,
                      channelTitle: video?.snippet?.channelTitle,
                      thumbnails: video?.snippet?.thumbnails,
                    },
                    statistics: {
                      viewCount: video?.statistics?.viewCount,
                    },
                  });
                  return acc;
                }, [] as any) || [];

              if (store.currentActiveFastGrowingKeyword?.keyword_tar) {
                const keywordInfoToStore: StoredKeywordItemValue = {
                  videosIdsArray: videosIdArray,
                  videosInfoArray: videosNeededInfo,
                  channelsInfoArray: channelNeededInfo,
                };
                setLocallyStoredData(
                  store.currentActiveFastGrowingKeyword?.keyword_tar +
                    '_fast_growing' +
                    `_${timePeriod}`,
                  keywordInfoToStore
                );
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setVideosDataLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setVideosDataLoading(false);
        });
    };

    const renderGraph = (
      currentKeyword: FastGrowingKeywordListItem,
      height = heightChart
    ) => {
      let formattedData = currentKeyword.diff_array
        // .reverse()
        .reduce((acc, item, index) => {
          // acc.push({x: index, y: item});
          acc.push({x: index + 1, y: item + 5});
          return acc;
        }, [] as any)
        .reverse();

      type point = {x: number; y: number};

      const filteredArray = formattedData.filter(
        (item: point) => item.y !== null || item.y !== undefined
      );

      if (formattedData.length < 2) {
        let additionalArray = Array.from({
          length: 30 - formattedData.length,
        }).reduce((acc, item, index) => {
          //@ts-ignore
          acc.push({x: index, y: null});
          return acc;
        }, [] as any) as any[];
        formattedData = [...additionalArray, ...formattedData].reduce(
          (acc, item, index) => {
            acc.push({x: index, y: item?.y || null});
            return acc;
          },
          [] as any
        );
        // formattedData = formattedData.concat(additionalArray);
      }

      if (formattedData.length < 7) {
        let additionalArray = Array.from({
          length: 7 - formattedData.length,
        }).reduce((acc, item, index) => {
          //@ts-ignore
          acc.push({x: index, y: formattedData?.[formattedData.length - 1]?.y});
          return acc;
        }, [] as any) as any[];

        formattedData = [...formattedData, ...additionalArray].reduce(
          (acc, item, index) => {
            acc.push({x: index, y: item?.y || null});
            return acc;
          },
          [] as any
        );
      }

      const xScale = scaleLinear()
        .range([8, filteredArray.length <= 1 ? width - 10 : width])
        .domain([2, formattedData.length - 2]);
      const yScale = scaleLinear()
        .range([height - 5, 5])
        .domain(minMax(formattedData, 'y'));

      return (
        <ResponsiveContainer h={height}>
          <Plot
            height={height}
            width={width}
            data={formattedData}
            scale={[xScale, yScale]}
          >
            <D3Tooltip tag={HoverLine} x="x" wMin={250}>
              {(props: any) => {
                console.log(props);

                const pillValue = useCallback(
                  () =>
                    timePeriod === TimePeriod.day
                      ? 0
                      : timePeriod === TimePeriod.week
                      ? 1
                      : 2,
                  [timePeriod]
                );

                const {
                  currentMoment,
                  sinceMoment,
                  currentValues,
                  pastValues,
                  differencePercent,
                  differenceValue,
                } = recountTooltipValues(currentKeyword, pillValue());
                return {
                  children: (
                    <DifferenceTooltip
                      currentMoment={currentMoment}
                      sinceMoment={sinceMoment}
                      currentValues={currentValues}
                      pastValues={pastValues}
                      differencePercent={differencePercent}
                      differenceValue={differenceValue}
                    />
                  ),
                } as any;
              }}
            </D3Tooltip>
            <Area x="x" y="y" curve={curveCardinal}>
              <Area.Line style={{strokeWidth: 1}}></Area.Line>
              <Area.Null></Area.Null>
              {filteredArray.length <= 1 && <Area.Dots></Area.Dots>}
            </Area>
          </Plot>
        </ResponsiveContainer>
      );
    };

    const createPDF = async () => {
      setShowTableBlock(true);
      setPdfLoading(true);
      setTimeout(() => {
        window.print();
      }, 500);

      setTimeout(() => {
        setShowTableBlock(false);
        setPdfLoading(false);
      }, 1000);
    };

    const competitiveRateText = useMemo(() => {
      return getCompetitiveRateText(
        store.currentActiveFastGrowingKeyword?.rate || 0
      );
    }, [store.currentActiveFastGrowingKeyword?.rate]);

    const competitiveSearchText = useMemo(() => {
      return getCompetitiveSearchText(
        store.currentActiveFastGrowingKeyword?.counts || 0,
        'fastGrowing'
      );
    }, [store.currentActiveFastGrowingKeyword?.counts]);

    const searchVolumeData = useMemo(() => {
      return (store.currentActiveFastGrowingKeyword?.counts || 1) /
        store.maxFastGrowingKeywordsSearchVolume >
        0.1
        ? store.currentActiveFastGrowingKeyword?.counts || 1
        : store.maxFastGrowingKeywordsSearchVolume * 0.1;
    }, [
      store?.currentActiveFastGrowingKeyword?.counts,
      store.maxFastGrowingKeywordsSearchVolume,
    ]);

    return (
      <Flex
        w="50%"
        pl={5}
        mr={4}
        pt={5}
        id="section-to-print"
        pb={6}
        direction="column"
        justifyContent={'space-between'}
      >
        <Box>
          <Box>
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize={'24px'} lineHeight={'28px'} fontWeight={700}>
                {store.currentActiveFastGrowingKeyword?.keyword_tar}
              </Text>

              <div className="no-pdf">
                <Button
                  size="m"
                  theme="muted"
                  use="secondary"
                  onClick={createPDF}
                  loading={pdfLoading}
                >
                  <Button.Addon>
                    <FileExportXS />
                  </Button.Addon>
                  <Button.Text>{'Export to PDF'}</Button.Text>
                </Button>
              </div>
            </Flex>
          </Box>
          <Box className="test">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              wMin={'553px'}
            >
              <InfoCardItem
                title="Competitive Rate"
                donutData={{
                  a: store.currentActiveFastGrowingKeyword?.rate || 0,
                  b: 100 - (store.currentActiveFastGrowingKeyword?.rate || 0),
                }}
                donutColor={getCompetitiveRateColor(
                  store.currentActiveFastGrowingKeyword?.rate || 0
                )}
                statsText={competitiveRateText}
                statsValue={store.currentActiveFastGrowingKeyword?.rate || 0}
                tooltipText={getTooltipTextByCompetitiveText(
                  competitiveRateText,
                  'top'
                )}
                minScaleValue={0}
                maxScaleValue={100}
                showScales
              />
              <InfoCardItem
                title="Search Volume"
                donutData={{
                  a: searchVolumeData,
                  b:
                    store.maxFastGrowingKeywordsSearchVolume +
                    10 -
                    (store.currentActiveFastGrowingKeyword?.counts || 0),
                }}
                donutColor={getCompetitiveSearchColor(
                  store.currentActiveFastGrowingKeyword?.counts || 0,
                  'fastGrowing'
                )}
                statsText={competitiveSearchText}
                statsValue={store.currentActiveFastGrowingKeyword?.counts || 0}
                tooltipText={getTooltipTextByCompetitiveText(
                  competitiveSearchText,
                  'fastGrowing'
                )}
                minScaleValue={0}
                maxScaleValue={Intl.NumberFormat('en', {
                  compactDisplay: 'short',
                  notation: 'compact',
                })?.format(store.maxFastGrowingKeywordsSearchVolume || 0)}
                showScales
                additionalLeftOffset
              />
            </Flex>
          </Box>

          <div className="table-test">
            <Box
              style={{
                borderRadius: 6,
                background: 'white',
                padding: 1,
              }}
              className="shadow-border"
            >
              <DataTable
                data={keywordDetailedData?.slice(0, props.amount)}
                sort={tableSort}
                onSortChange={setTableSort}
                use="secondary"
                style={{borderRadius: 6}}
              >
                <DataTable.Head style={{backgroundColor: 'white'}}>
                  <DataTable.Column
                    name="keyword"
                    children="Related Keywords"
                    flex={5}
                    // sortable
                  />
                  <DataTable.Column
                    name="diff_array"
                    children="Trend"
                    flex={3}
                    justifyContent="end"
                    // sortable
                  />
                  <DataTable.Column
                    name="last_diff"
                    children="Change"
                    flex={1}
                    justifyContent="end"
                    sortable
                  />
                  <DataTable.Column
                    flex={1}
                    justifyContent="start"
                    name="counts"
                    children="Search Volume"
                    // sortable
                  />
                </DataTable.Head>
                {!loading &&
                  (!keywordDetailedData ||
                    keywordDetailedData?.length === 0) && <NoData h={200} />}
                <DataTable.Body
                  style={loading ? {minHeight: 180} : {}}
                  tag={SpinContainer}
                  loading={loading}
                >
                  <DataTable.Cell name="keyword">
                    {(props: any, row: DataTableData, index: number) => {
                      return {
                        style: {
                          alignItems: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        children: (
                          <Ellipsis
                            maxLine={1}
                            tooltip={
                              (props.children.props.children + '').length > 28
                            }
                            trim="end"
                            containerRect={{width: 156}}
                          >
                            {props.children.props.children}
                          </Ellipsis>
                        ),
                      };
                    }}
                  </DataTable.Cell>
                  <DataTable.Cell name="keyword/last_diff/counts">
                    {(props: any, row: DataTableData, index: number) => {
                      return {
                        style: {
                          alignItems: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                        // children: props.children
                      };
                    }}
                  </DataTable.Cell>

                  <DataTable.Cell name="counts">
                    {(props: any, row: DataTableData, index: number) => {
                      console.log(props);

                      return {
                        children: (
                          <div style={{paddingRight: 20}}>
                            {Intl.NumberFormat('en', {
                              compactDisplay: 'short',
                              notation: 'compact',
                            })?.format(props.children.props.children || 0)}
                          </div>
                        ),
                      };
                    }}
                  </DataTable.Cell>
                  <DataTable.Cell name="diff_array">
                    {(props: any, row: DataTableData, index: number) => {
                      return {
                        style: {
                          paddingLeft: 0,
                          paddingRight: 0,
                        },
                        children: renderGraph(
                          keywordDetailedData?.[index]!,
                          35
                        ),
                      };
                    }}
                  </DataTable.Cell>
                  <DataTable.Cell name="last_diff">
                    {(props: any, row: any, index: number) => {
                      //@ts-ignore
                      console.log(
                        'long naming',
                        props.children?.props?.children
                      );
                      const value = props.children?.props?.children;
                      return {
                        children: (
                          <Box
                            className={
                              value > 0
                                ? style.positiveChange
                                : value < 0
                                ? style.negativeChange
                                : {}
                            }
                          >
                            <Tooltip>
                              <Tooltip.Trigger>
                                <Flex alignItems="center">
                                  {value > 0 ? (
                                    <ArrowUp fill="green" height={14} />
                                  ) : value < 0 ? (
                                    <ArrowDown fill="red" height={14} />
                                  ) : null}

                                  <span
                                    style={{
                                      fontSize: 14,
                                      lineHeight: '20px',
                                    }}
                                  >
                                    {Intl.NumberFormat('en', {
                                      compactDisplay: 'short',
                                      notation: 'compact',
                                    })?.format(
                                      props.children.props.children || 0
                                    )}
                                  </span>
                                </Flex>
                              </Tooltip.Trigger>
                              <Tooltip.Popper>
                                {
                                  'Change of keyword performance during selected time period'
                                }
                              </Tooltip.Popper>
                            </Tooltip>
                          </Box>
                        ),
                      };
                    }}
                  </DataTable.Cell>
                </DataTable.Body>
              </DataTable>
            </Box>
          </div>
        </Box>
        <Box mt={3} mb={4} className="no-pdf">
          <SpinContainer loading={videosDataLoading} hMin={180}>
            {!videosDataLoading && (!videoData || videoData.length === 0) ? (
              // <NoData description="Try selecting a different time period or changing your keyword." />
              <WidgetEmpty icon={getIconPath('nothing-found')}>
                <WidgetEmpty.Title>
                  We have no related videos to show
                </WidgetEmpty.Title>
                <WidgetEmpty.Description>
                  Try selecting a different time period or changing your
                  keyword.
                </WidgetEmpty.Description>
              </WidgetEmpty>
            ) : (
              <VideoSwiperSection
                videosData={videoData}
                channelsData={channelsData}
              />
            )}
          </SpinContainer>
        </Box>
        <div
          style={showTableBlock ? {marginTop: 12} : {display: 'none'}}
          className="additional-pdf-info"
        >
          <div className="add-pdf">
            <Text fontSize={'16px'} lineHeight={'24px'} fontWeight={700}>
              Top popular video
            </Text>
            <Box mt={3}>
              <Row gutter={3}>
                {videoData.map((videoInfo, index) => {
                  const videoOwnersChannel = channelsData?.find(
                    (channel) => channel.id === videoInfo.snippet.channelId
                  );
                  if (!videoOwnersChannel) return null;
                  return (
                    <Col span={6} mb={6}>
                      <div
                        className="shadow-border print-card"
                        style={{marginLeft: 2, height: '100%', padding: 4}}
                      >
                        <Text>{videoInfo.snippet.title}</Text>
                        <Flex direction="column" alignItems="flex-start" mt={1}>
                          <Text
                            fontWeight={400}
                            fontSize={'14px'}
                            lineHeight={'16px'}
                          >
                            {videoOwnersChannel.snippet.title}
                          </Text>

                          <Flex>
                            <Text
                              mt={1}
                              fontSize={'12px'}
                              lineHeight={'16px'}
                              color="#828282"
                            >
                              {`${convertNumberToShortString(
                                videoOwnersChannel.statistics
                                  ?.subscriberCount || 0
                              )} subscribers, `}
                              {`${convertNumberToShortString(
                                videoInfo.statistics.viewCount || 0
                              )} views ${moment(
                                videoInfo.snippet.publishedAt
                              ).fromNow()}`}
                            </Text>
                          </Flex>
                        </Flex>

                        <Box mt={1}>
                          <Text>
                            URL:
                            <Link style={{whiteSpace: 'pre-wrap'}}>
                              {`https://www.youtube.com/watch?v=${videoInfo.id}`}
                            </Link>
                          </Text>
                        </Box>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Box>
          </div>
        </div>
        {/* </div> */}
      </Flex>
    );
  }
);
