import DataTable, {DataTableData, DataTableSort} from '@semcore/ui/data-table';
import {useEffect, useMemo, useState} from 'react';

import FileExportXS from '@semcore/icon/lib/FileExport/xs';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import required modules
import {Mousewheel, Scrollbar, Navigation} from 'swiper';
import {Swiper as SwiperType} from 'swiper/types';
import Link from '@semcore/ui/link';
import {Box, Flex} from '@semcore/ui/flex-box';

import {Text} from '@semcore/ui/typography';
import Button from '@semcore/ui/button';
import {InfoCardItem} from '../../../components/InfoCardItem/InfoCardItem';

import {VideoSwiperSection} from '../../../components/VideoSwiperSection/VideoSwiperSection';
import {
  getChannelInfo,
  getKeywordsList,
  GetSearchKeywordList,
  getTopVideosIdByKeyword,
  getYoutubeVideoInfo,
  KeywordListItem,
  KeywordsList,
  TopVideosByKeywordResponse,
  YoutubeChannelItem,
  YoutubeVideoInfoItem,
} from '../../../api/request';
import store from '../../../stores/store';
import Spin from '@semcore/ui/spin';
import SpinContainer from '@semcore/spin-container';

import {observer} from 'mobx-react';
import {
  convertNumberToShortString,
  getCompetitiveRateColor,
  getCompetitiveRateText,
  getCompetitiveSearchColor,
  getCompetitiveSearchText,
  getLocallyStoredData,
  getTooltipTextByCompetitiveText,
  setLocallyStoredData,
} from '../../../utils/helpers';
import {StoredKeywordItemValue} from '../../../types/entity';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import WidgetEmpty, {getIconPath, NoData} from '@semcore/ui/widget-empty';
import {CompetitiveRateCircle} from '../../../components/CompetitiveRateCircle';

// import {toCanvas, toPng} from 'html-to-image';

import {Row, Col} from '@semcore/ui/grid';
import moment from 'moment';

interface Props {
  amount: number;
}

export const KeywordDetailedInfoSection: React.FC<Props> = observer((props) => {
  const [tableSort, setTableSort] = useState<DataTableSort & string[]>([
    'searches',
    'desc',
  ]);
  const [loading, setLoading] = useState<boolean>(true);

  const [keywordDetailedData, setKeywordDetailedData] =
    useState<KeywordsList | null>(null);
  const [videoData, setVideoData] = useState<YoutubeVideoInfoItem[]>([]);
  const [videosDataLoading, setVideosDataLoading] = useState<boolean>(true);
  const [channelsData, setChannelsData] = useState<YoutubeChannelItem[]>([]);

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const [showTableBlock, setShowTableBlock] = useState<boolean>(false);

  const activeKeywordInStore = store.currentActiveKeyword?.keyword;

  const timePeriod = store.chosenAggregationDate;

  useEffect(() => {
    if (!store.currentActiveKeyword?.keyword) {
      return;
    }
    setLoading(true);
    setTimeout(() => {
      const searchObj: GetSearchKeywordList = {
        searchKeyword: store.currentActiveKeyword?.keyword ?? '',
        period: store.chosenAggregationDate,
        country: store.chosenCountry,
        per_page: 30,
        order_by: tableSort.join(' '),
        page: 1,
      };
      getKeywordsList(searchObj)
        .then((json) => {
          console.log('json', json);
          setKeywordDetailedData(json);
          // store.findMaximumSearchVolume(json);
        })
        .catch((err) => {
          setKeywordDetailedData(null);
          console.log(err);
        })
        .finally(() => setLoading(false));
      if (store.currentActiveKeyword) {
        const storedKeywordData = getLocallyStoredData(
          store.currentActiveKeyword?.keyword + `_${timePeriod}`
        ) as StoredKeywordItemValue | null;
        if (storedKeywordData) {
          setVideoData(storedKeywordData.videosInfoArray);
          setChannelsData(storedKeywordData.channelsInfoArray);
          setVideosDataLoading(false);
          return;
        }
        if (
          store.currentActiveKeyword?.video_ref &&
          store.currentActiveKeyword.video_ref?.length > 0
        ) {
          const videosIdArray = store.currentActiveKeyword.video_ref.map(
            (item) => {
              const id = item.split(',')?.[0];
              return id;
            }
          );
          getVideoInfo(videosIdArray);
        } else {
          getTopVideosIdByKeyword(store.currentActiveKeyword.keyword).then(
            (json) => {
              console.log('getTopVideosIdByKeywordSuccess', json);
              if (json.items.length > 0) {
                const videoIdList = json.items.map((video) => video.id.videoId);
                getVideoInfo(videoIdList);
              }
            }
          );
        }
      }
    }, 500);
  }, [activeKeywordInStore, tableSort[0], tableSort[1], timePeriod]);

  // const getVideoInfo = (json: TopVideosByKeywordResponse) => {
  const getVideoInfo = (videosIdArray: string[]) => {
    setVideosDataLoading(true);

    getYoutubeVideoInfo(videosIdArray)
      .then((videoJSON) => {
        const videoDataArray = videoJSON.items.sort((a, b) => {
          const currentIdFromServer =
            store.currentActiveKeyword?.video_ref?.find(
              (storeVideo) => storeVideo?.split(',')?.[0] === a.id
            );
          const nextIdFromServer = store.currentActiveKeyword?.video_ref?.find(
            (storeVideo) => storeVideo?.split(',')?.[0] === b.id
          );

          const currentIdVolume =
            Number(currentIdFromServer?.split(',')?.[1]) || 0;
          const nextIdVolume = Number(nextIdFromServer?.split(',')?.[1]) || 0;

          return nextIdVolume - currentIdVolume;
        });
        // setVideoData(videoJSON.items);
        console.log('videoDataArray', store.currentActiveKeyword?.video_ref);
        console.log('videoDataArray', videoDataArray);
        setVideoData(videoDataArray);

        const channelsIdArray = videoJSON.items.map(
          (video) => video.snippet.channelId
        );

        getChannelInfo(channelsIdArray)
          .then((channelJSON) => {
            setChannelsData(channelJSON.items);

            const channelNeededInfo =
              channelJSON?.items?.reduce((acc, item, index) => {
                acc.push({
                  id: item.id,
                  statistics: {
                    subscriberCount: item.statistics.subscriberCount,
                  },
                  snippet: {
                    thumbnails: {
                      medium: {url: item.snippet.thumbnails.medium.url},
                    },
                  },
                });
                return acc;
              }, [] as any) || [];

            const videosNeededInfo =
              videoJSON?.items?.reduce((acc, video, index) => {
                acc.push({
                  id: video.id,
                  snippet: {
                    channelId: video?.snippet?.channelId,
                    publishedAt: video?.snippet?.publishedAt,
                    title: video?.snippet?.title,
                    channelTitle: video?.snippet?.channelTitle,
                    thumbnails: video?.snippet?.thumbnails,
                  },
                  statistics: {
                    viewCount: video?.statistics?.viewCount,
                  },
                });
                return acc;
              }, [] as any) || [];

            if (store.currentActiveKeyword?.keyword) {
              const keywordInfoToStore: StoredKeywordItemValue = {
                videosIdsArray: videosIdArray,
                videosInfoArray: videosNeededInfo,
                channelsInfoArray: channelNeededInfo,
              };
              setLocallyStoredData(
                store.currentActiveKeyword?.keyword + `_${timePeriod}`,
                keywordInfoToStore
              );
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setVideosDataLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setVideosDataLoading(false);
      });
  };

  const createPDF = async () => {
    setShowTableBlock(true);
    setPdfLoading(true);
    setTimeout(() => {
      window.print();
    }, 500);
    setTimeout(() => {
      setShowTableBlock(false);
      setPdfLoading(false);
    }, 1000);
  };

  const competitiveRateText = useMemo(() => {
    return getCompetitiveRateText(store.currentActiveKeyword?.rate || 0);
  }, [store.currentActiveKeyword?.rate]);

  const competitiveSearchText = useMemo(() => {
    return getCompetitiveSearchText(
      store.currentActiveKeyword?.searches || 0,
      'top'
    );
  }, [store.currentActiveKeyword?.searches]);

  const searchVolumeData = useMemo(() => {
    return (store.currentActiveKeyword?.searches || 1) / store.maxSearchVolume >
      0.1
      ? store.currentActiveKeyword?.searches || 1
      : store.maxSearchVolume * 0.1;
  }, [store?.currentActiveKeyword?.searches, store.maxSearchVolume]);

  return (
    <Flex
      w={'50%'}
      ml={5}
      mr={4}
      pt={5}
      pb={6}
      id="section-to-print"
      direction="column"
      justifyContent={'space-between'}
    >
      <Box>
        <Box>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            wMin={'553px'}
          >
            <Text fontSize={'24px'} lineHeight={'28px'} fontWeight={700}>
              {store.currentActiveKeyword?.keyword}
            </Text>
            <div className="no-pdf">
              <Button
                size="m"
                theme="muted"
                use="secondary"
                onClick={createPDF}
                loading={pdfLoading}
              >
                <Button.Addon>
                  <FileExportXS />
                </Button.Addon>
                <Button.Text>{'Export to PDF'}</Button.Text>
              </Button>
            </div>
          </Flex>
        </Box>
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <InfoCardItem
              title="Competitive Rate"
              donutData={{
                a: store.currentActiveKeyword?.rate || 0,
                b: 100 - (store.currentActiveKeyword?.rate || 0),
              }}
              donutColor={getCompetitiveRateColor(
                store.currentActiveKeyword?.rate || 0
              )}
              statsText={competitiveRateText}
              statsValue={store.currentActiveKeyword?.rate || 0}
              tooltipText={getTooltipTextByCompetitiveText(
                competitiveRateText,
                'top'
              )}
              showScales
              minScaleValue={0}
              maxScaleValue={100}
            />
            <InfoCardItem
              title="Search Volume"
              donutData={{
                a: searchVolumeData,
                b:
                  store.maxSearchVolume +
                  10 -
                  (store.currentActiveKeyword?.searches || 0),
              }}
              donutColor={getCompetitiveSearchColor(
                store.currentActiveKeyword?.searches || 0,
                'top'
              )}
              statsText={competitiveSearchText}
              statsValue={store.currentActiveKeyword?.searches || 0}
              tooltipText={getTooltipTextByCompetitiveText(
                competitiveSearchText,
                'fastGrowing'
              )}
              showScales
              minScaleValue={0}
              maxScaleValue={Intl.NumberFormat('en', {
                compactDisplay: 'short',
                notation: 'compact',
              })?.format(store.maxSearchVolume || 0)}
            />
          </Flex>
        </Box>

        <Box
          style={{
            borderRadius: 6,
            background: 'white',
            padding: 1,
          }}
          className="shadow-border test"
        >
          <DataTable
            data={keywordDetailedData?.slice(0, props.amount)}
            // sort={tableSort}
            // onSortChange={setTableSort}
            use="secondary"
            style={{borderRadius: 6}}
          >
            <DataTable.Head style={{backgroundColor: 'white'}}>
              <DataTable.Column
                name="keyword"
                children="Related Keywords"
                flex={2}
                // sortable
              />
              <DataTable.Column
                name="searches"
                children="Search Volume"
                flex={1}
                justifyContent="end"
                sortable
              />
              <DataTable.Column
                flex={1}
                justifyContent="end"
                name="rate"
                children="Competitive"
                // sortable
              />
            </DataTable.Head>
            {!loading &&
              (!keywordDetailedData || keywordDetailedData?.length === 0) && (
                <NoData h={200} />
              )}
            <DataTable.Body
              style={loading ? {minHeight: 180} : {}}
              loading={loading}
              tag={SpinContainer}
            >
              <DataTable.Cell name="rate">
                {(props, row, index) => {
                  console.log('!!weewoo', row);
                  return {
                    children: (
                      <Flex
                        alignItems="center"
                        key={`${row?.rate}-key-${index}`}
                      >
                        <Box mr={'6px'}>{props.children}</Box>
                        <Box>
                          <Flex alignItems="flex-start">
                            <CompetitiveRateCircle
                              rate={(row?.rate as number) || 0}
                            />
                          </Flex>
                        </Box>
                      </Flex>
                    ),
                  };
                }}
              </DataTable.Cell>
              <DataTable.Cell name="searches">
                {(props: any, row: DataTableData, index: number) => {
                  console.log(props);

                  return {
                    children: Intl.NumberFormat('en', {
                      compactDisplay: 'short',
                      notation: 'compact',
                    })?.format(props.children.props.children || 0),
                  };
                }}
              </DataTable.Cell>
            </DataTable.Body>
          </DataTable>
        </Box>
      </Box>

      <Box pt={3} pb={4} className="no-pdf">
        <SpinContainer loading={videosDataLoading} hMin={180}>
          {!videosDataLoading && (!videoData || videoData.length === 0) ? (
            // <NoData description="Try selecting a different time period or changing your keyword." />
            <WidgetEmpty icon={getIconPath('nothing-found')}>
              <WidgetEmpty.Title>
                We have no related videos to show
              </WidgetEmpty.Title>
              <WidgetEmpty.Description>
                Try selecting a different time period or changing your keyword.
              </WidgetEmpty.Description>
            </WidgetEmpty>
          ) : (
            <VideoSwiperSection
              videosData={videoData}
              channelsData={channelsData}
            />
          )}
        </SpinContainer>
      </Box>

      <div
        style={showTableBlock ? {marginTop: 12} : {display: 'none'}}
        className="additional-pdf-info"
      >
        <div className="add-pdf">
          <Text fontSize={'16px'} lineHeight={'24px'} fontWeight={700}>
            Top popular video
          </Text>
          <Box mt={4}>
            <Row gutter={2}>
              {videoData.map((videoInfo, index) => {
                const videoOwnersChannel = channelsData?.find(
                  (channel) => channel.id === videoInfo.snippet.channelId
                );
                if (!videoOwnersChannel) return null;
                return (
                  <Col span={6} mb={6}>
                    <div
                      className="shadow-border print-card"
                      style={{marginLeft: 2, height: '100%', padding: 4}}
                    >
                      <Text>{videoInfo.snippet.title}</Text>
                      <Flex direction="column" alignItems="flex-start" mt={1}>
                        <Text
                          fontWeight={400}
                          fontSize={'14px'}
                          lineHeight={'16px'}
                        >
                          {videoOwnersChannel.snippet.title}
                        </Text>

                        <Flex>
                          <Text
                            mt={1}
                            fontSize={'12px'}
                            lineHeight={'16px'}
                            color="#828282"
                          >
                            {`${convertNumberToShortString(
                              videoOwnersChannel.statistics?.subscriberCount ||
                                0
                            )} subscribers, `}
                            {`${convertNumberToShortString(
                              videoInfo.statistics.viewCount || 0
                            )} views ${moment(
                              videoInfo.snippet.publishedAt
                            ).fromNow()}`}
                          </Text>
                        </Flex>
                      </Flex>

                      <Box mt={1}>
                        <Text>
                          URL:
                          <Link style={{whiteSpace: 'pre-wrap'}}>
                            {`https://www.youtube.com/watch?v=${videoInfo.id}`}
                          </Link>
                        </Text>
                      </Box>
                    </div>
                    {/* </Box> */}
                  </Col>
                );
              })}
            </Row>
          </Box>
        </div>
      </div>
    </Flex>
  );
});
