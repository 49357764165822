import React, {useCallback, useMemo} from 'react';
import {
  GreenStatusCircle,
  OrangeStatusCircle,
  StatusCircle,
  YellowStatusCircle,
} from '../assets/img';

interface Props {
  rate: number;
}

export const CompetitiveRateCircle: React.FC<Props> = ({rate}) => {
  const renderIcon = useCallback(() => {
    console.log('rate', rate);
    if (rate <= 25) {
      // return <StatusCircle />;
      return <GreenStatusCircle />;
    } else if (rate <= 50) {
      // return <OrangeStatusCircle />;
      return <YellowStatusCircle />;
    } else if (rate <= 75) {
      // return <YellowStatusCircle />;
      return <OrangeStatusCircle />;
    } else {
      // return <GreenStatusCircle />;
      return <StatusCircle />;
    }
  }, [rate]);
  return <div style={{height: 16}}>{renderIcon()}</div>;
};
