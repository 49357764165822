import React, {Component} from 'react';
import {Info} from '@semcore/ui/product-head';
import Select from '@semcore/ui/select';
import {LinkTrigger} from '@semcore/ui/base-trigger';
import Divider from '@semcore/ui/divider';

interface IComponent {
  value: {
    value: number;
    label: string;
  };
  data: Array<{value: number; label: string}>;
  onChange: any;
}

class SelectDomainLink extends Component<IComponent> {
  renderOptions = (item: any) => {
    return (
      <Select.Option.Checkbox value={item.value}>
        {item?.title}
      </Select.Option.Checkbox>
    );
  };

  render() {
    const {value, data, onChange} = this.props;
    return (
      <Info>
        <Info.Item label="Domains:">
          <Select onChange={onChange}>
            <Select.Trigger tag={LinkTrigger}>
              <Select.Trigger.Text>{value.label}</Select.Trigger.Text>
            </Select.Trigger>
            <Select.Popper>
              <Select.List>
                <Select.Option value={'all'}>{'All'}</Select.Option>
                <Divider />
                {data.map((option, index) => {
                  return (
                    <Select.Option value={option.value} key={index}>
                      {option.label}
                    </Select.Option>
                  );
                })}
              </Select.List>
            </Select.Popper>
          </Select>
        </Info.Item>
      </Info>
    );
  }
}

export default SelectDomainLink;
