import React, {useEffect, useState} from 'react';
import Select from '@semcore/select';
import Flags from '@semcore/flags';
import {LinkTrigger} from '@semcore/ui/base-trigger';
import {Text} from '@semcore/ui/typography';
import {AvailableCountriesList, FullCountriesNames} from '../../types/enums';
import store from '../../stores/store';
import {observer} from 'mobx-react';
import Divider from '@semcore/ui/divider';

const formatName = (name: string) => name?.replace(/([a-z])([A-Z])/g, '$1 $2');

type isoFlag = keyof typeof AvailableCountriesList;

export const CountrySelect = observer(() => {
  const [value, setValue] = useState<isoFlag>('USA');

  useEffect(() => {
    // getting saved value from localStorage
    let lastSavedCountry: string = 'USA';
    try {
      lastSavedCountry = localStorage?.getItem('chosenCountry') || 'USA';
    } catch (err) {
      console.log(err);
    }

    if (lastSavedCountry) {
      handleSelectChange(lastSavedCountry as isoFlag);
    }
  }, []);

  const handleSelectChange = (value: isoFlag) => {
    setValue(value);

    store.setChosenCountry(value);
    store.clearMaximumSearchVolume();
  };

  return (
    <>
      <Text style={{alignSelf: 'center', fontSize: 14}} mr={1}>
        Location:{' '}
      </Text>
      <Select
        onChange={handleSelectChange}
        placeholder="Select country"
        value={value}
      >
        <Select.Trigger tag={LinkTrigger}>
          <Select.Trigger.Text>
            {formatName(FullCountriesNames[value])}
          </Select.Trigger.Text>
        </Select.Trigger>
        <Select.Menu hMax={180}>
          <Select.Option value={'all'}>{'All Regions'}</Select.Option>
          <Divider />
          {Object.keys(AvailableCountriesList).map((country) => {
            if (country === 'all') return null;
            return (
              <Select.Option key={country} value={country}>
                {/* @ts-ignore */}
                <Flags iso3={country as Omit<isoFlag, 'all'>} mr={2} />

                {formatName(FullCountriesNames[country as isoFlag])}
              </Select.Option>
            );
          })}
        </Select.Menu>
      </Select>
    </>
  );
});
