import Button from '@semcore/ui/button';
import {Box, Flex} from '@semcore/ui/flex-box';
import Input from '@semcore/ui/input';
import Select from '@semcore/ui/select';
import React from 'react';

interface Props {
  handleSearchInputAction: (value: string) => void;
  currentSearchValue: string;
  handleKeyboardPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearchButtonPress: () => void;
}

export const SearchInput: React.FC<Props> = ({
  handleKeyboardPress,
  handleSearchButtonPress,
  handleSearchInputAction,
  currentSearchValue,
}) => {
  return (
    <Box>
      <Flex>
        <Select
          state={'normal'}
          interaction="focus"
          onChange={handleSearchInputAction}
          value={currentSearchValue}
          size="l"
        >
          <Select.Trigger size={'m'} w={'166px'} m={'0 8px 0 0'} tag={Input}>
            <Select.Trigger.Text wMin={'100%'}>
              <Input.Value
                wMin={'100%'}
                onChange={handleSearchInputAction}
                value={currentSearchValue}
                placeholder={'Search'}
                onKeyUp={handleKeyboardPress}
                size="m"
              />
            </Select.Trigger.Text>
          </Select.Trigger>
        </Select>

        <Button
          onClick={handleSearchButtonPress}
          use="primary"
          theme="success"
          size="m"
        >
          <Button.Text>Search</Button.Text>
        </Button>
      </Flex>
    </Box>
  );
};
