import DataTable, {DataTableData, DataTableSort} from '@semcore/ui/data-table';
import {Box, Flex} from '@semcore/ui/flex-box';
import Tooltip from '@semcore/ui/tooltip';
import {Text} from '@semcore/ui/typography';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {TimePeriod, TopVideoType, YoutubeChannelItem} from '../../api/request';
import {convertNumberToShortString} from '../../utils/helpers';
import SpinContainer from '@semcore/spin-container';
import Ellipsis from '@semcore/ui/ellipsis';

interface Props {
  index: number;
  video: TopVideoType;
  channelInfo: YoutubeChannelItem;

  timePeriod: TimePeriod;
}

export const TopVideoCard: React.FC<Props> = ({
  index,
  video,
  channelInfo,
  timePeriod,
}) => {
  const [videoKeywords, setVideoKeywords] = useState<string[]>([]);
  const [tableSort, setTableSort] = useState<DataTableSort & string[]>([
    'traffic',
    'desc',
  ]);

  useEffect(() => {
    console.log(video.result);
    const keywordsInfo = parseTrafficInfo(video.result);

    setVideoKeywords(keywordsInfo);
  }, [JSON.stringify(video.result)]);

  useEffect(() => {
    console.log('WAS HERE');
    changeKeywordsOrder();
  }, [JSON.stringify(tableSort)]);

  const changeKeywordsOrder = () => {
    let keywords: string[] = JSON.parse(JSON.stringify(video.result));

    keywords.sort((a, b) => {
      let firstTrafficArray: string[] = a.split(',');
      let firstTrafficPercent = +firstTrafficArray?.[2] || 0;

      let secondTrafficArray: string[] = b.split(',');
      let secondTrafficPercent = +secondTrafficArray?.[2] || 0;

      return tableSort[1] === 'asc'
        ? firstTrafficPercent - secondTrafficPercent
        : secondTrafficPercent - firstTrafficPercent;
    });

    console.log('new keywords', keywords);
    setVideoKeywords(keywords);
  };

  const parseTrafficInfo = (videoKeywords: string[]) => {
    const keywords =
      videoKeywords.length > 0
        ? videoKeywords
        : video.result?.slice(0, 5) || [];
    return keywords.reduce((acc, keywordInfo) => {
      // "lofi,121,28.74"
      const keywordArray = keywordInfo?.split?.(',');
      if (keywordArray?.length === 0) return;
      acc.push({
        keyword: keywordArray?.[0] || '',
        traffic: keywordArray?.[2] || 0,
      });
      return acc;
    }, [] as any);
  };
  const handleVideoClick = () => {
    window.open(`https://www.youtube.com/watch?v=${video.video_id}`, '__blank');
  };
  const parseVideo = () => {
    if (typeof videoKeywords?.[0] === 'string') {
      return parseTrafficInfo(videoKeywords.slice(0, 5));
    } else {
      return videoKeywords.slice(0, 5);
    }
  };
  return (
    <Box
      key={`index-top-video-${index}-${video.link || video.counts}`}
      px={4}
      pt={4}
      pb={3}
      style={{borderRadius: 6, background: 'white'}}
      w={315}
      h={550}
      className="shadow-border"
    >
      <div onClick={handleVideoClick} style={{cursor: 'pointer'}}>
        <div
          style={{
            background: `url('${video.picture}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: 154,
            width: '100%',
            marginBottom: 12,
          }}
        ></div>
        <Tooltip wMin={290}>
          <Tooltip.Popper>
            <span style={{fontWeight: 700}}>{video.title}</span>
            <br />
            <span>{`${video.channel_name} / ${convertNumberToShortString(
              channelInfo.statistics?.subscriberCount || 0
            )} subscribers`}</span>
          </Tooltip.Popper>
          <Tooltip.Trigger
            style={{display: 'flex', alignItems: 'center', height: 52}}
          >
            <Text
              mt={3}
              fontSize={'16px'}
              fontWeight={700}
              lineHeight={'20px'}
              className="line-clamp"
              w="296px"
            >
              {video.title}
            </Text>
          </Tooltip.Trigger>
        </Tooltip>
      </div>

      <Box mt={2}>
        <div style={{cursor: 'pointer'}} onClick={handleVideoClick}>
          <Flex direction="row" alignItems="center">
            <div
              style={{
                background: `url('${channelInfo.snippet?.thumbnails?.medium?.url}')`,
                backgroundColor: 'gray',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: 36,
                width: 36,
                borderRadius: '50%',
                marginRight: 12,
              }}
            ></div>
            {/* <Box mt={3}> */}
            <Flex direction="column" alignItems="flex-start">
              <Text fontWeight={400} fontSize={'14px'} lineHeight={'16px'}>
                {video.channel_name}
              </Text>
              <Text
                fontSize={'12px'}
                lineHeight={'16px'}
                color="#828282"
              >{`${convertNumberToShortString(
                channelInfo.statistics?.subscriberCount || 0
              )} subscribers`}</Text>
            </Flex>
            {/* </Box> */}
          </Flex>
          <Box mt={3}>
            <Text
              fontSize={'12px'}
              lineHeight={'16px'}
              color="#828282"
            >{`${convertNumberToShortString(video.num_views)} views ${moment(
              video.upload_date
            ).fromNow()} / ${convertNumberToShortString(
              (video.counts < video.num_views
                ? video.counts
                : video.num_views) || 0
            )} views last ${
              timePeriod === TimePeriod.day
                ? 'day'
                : timePeriod === TimePeriod.week
                ? 'week'
                : 'month'
            }`}</Text>
          </Box>
        </div>

        <Box mt={4}>
          <DataTable
            data={parseVideo()}
            use="secondary"
            // sort={tableSort}
            // onSortChange={setTableSort}
          >
            <DataTable.Head>
              <DataTable.Column
                name="keyword"
                children="Top 5 keywords"
                flex={5}
                // sortable
              />
              <DataTable.Column
                name="traffic"
                children="% Traffic"
                flex={2}
                justifyContent="end"
                sortable
              />
            </DataTable.Head>
            <DataTable.Body>
              <DataTable.Cell name="keyword">
                {(props: any, row: DataTableData, index: number) => {
                  return {
                    style: {
                      alignItems: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                    children: (
                      <Box
                        w={'200px'}
                        // style={{textOverflow: 'ellipsis', overflow: 'hidden'}}
                      >
                        {/*@ts-ignore*/}
                        <Ellipsis
                          tooltip={props.children?.props?.children?.length > 31}
                        >
                          {props.children}
                        </Ellipsis>
                      </Box>
                    ),
                  };
                }}
              </DataTable.Cell>
            </DataTable.Body>
          </DataTable>
        </Box>
      </Box>
    </Box>
  );
};
